// @component
//   @param {Array} props.events - The list of events to display in the day view. Each event should have `title` and `start_time` properties.
//   @param {Date} props.currentDay - The currently selected day to display the calendar.
 
//   @example
//  // Example usage of DayView component

//   import React, { useState } from 'react';
//   import DayView from './DayView';
  
//   const events = [
//       { 
//           title: 'Team Meeting', 
//           start_time: '2024-12-19T10:00:00' // Event at 10:00 AM 
//      },
//       { 
//           title: 'Client Call', 
//           start_time: '2024-12-19T14:30:00' // Event at 2:30 PM
//       },
//   ];
  
//   function App() {
//       const [selectedDay, setSelectedDay] = useState(new Date()); // Default to current day
  
//       return (
//          <div>
//              <h1>Day View Calendar</h1>
//              <DayView 
//                  events={events} // Pass the list of events
//                  currentDay={selectedDay} // Pass the selected day
//              />
//          </div>
//      );
//  }
 
//   export default App;
 
import React, { useEffect, useState , useMemo } from 'react';

export default function DayView({ events = [], currentDay, date, dayCalendarTasks }) {
    const [timeSlots, setTimeSlots] = useState([]);

    // Ensure currentDay is always valid
    const safeCurrentDay = useMemo(() => {
        return currentDay instanceof Date && !isNaN(currentDay) ? currentDay : new Date();
    }, [currentDay]);

    // Utility to check if two dates are on the same day
    const isSameDay = (date1, date2) => {
        return (
            date1.getDate() === date2.getDate() &&
            date1.getMonth() === date2.getMonth() &&
            date1.getFullYear() === date2.getFullYear()
        );
    };

    useEffect(() => {
        // console.log('Generating time slots...');
        const generateTimeSlots = () => {
            const slots = [];
            for (let i = 0; i < 24; i++) {
                const hour = i % 12 === 0 ? 12 : i % 12; // 12-hour format
                const period = i < 12 ? 'AM' : 'PM';
                const timeLabel = `${hour}:00 ${period}`;
                const slotEvents = events.filter(event => {
                    const eventHour = new Date(event.start_time).getHours();
                    return eventHour === i && isSameDay(new Date(event.start_time), currentDay);
                });
                slots.push({ time: timeLabel, events: slotEvents });
            }
            return slots;
        };
    
        setTimeSlots(generateTimeSlots());
    }, [events, currentDay]);
    
    return (
        <div className="fin_man_day_calendar_container">
            <div className="d-flex day_calendar_top_header">
                <div className="time"></div>
                <div className="task d-flex justify-content-center fs-5">
                    {safeCurrentDay.toLocaleDateString('en-IN', { weekday: 'long' })},{" "}
                    {safeCurrentDay.toLocaleDateString('en-IN', { day: 'numeric', month: 'long', year: 'numeric' })}
                </div>
            </div>

            {timeSlots.map((slot, index) => (
                <div className="d-flex" key={index}>
                    <div className="time">{slot.time}</div>
                    <div className={slot.events.length > 0 ? 'task' : 'task-add'}>
                        {slot.events.length > 0 ? (
                            slot.events.map((event, idx) => (
                                <div
                                    key={idx}
                                    className="task-details"
                                    title={`${event.title}, ${new Date(event.start_time).toLocaleTimeString([], {
                                        hour: '2-digit',
                                        minute: '2-digit',
                                    })}`}
                                >
                                    {event.title}, {new Date(event.start_time).toLocaleTimeString([], {
                                        hour: '2-digit',
                                        minute: '2-digit',
                                    })}
                                </div>
                            ))
                        ) : null}
                    </div>
                </div>
            ))}
        </div>
    );
}
