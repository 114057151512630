import React, { useEffect, useState, useRef } from "react";
import NavBarTop from "../Reusable_Components/NavBarTop";
import LeftNavBar from "../Reusable_Components/LeftNavBar";
import { GoSearch } from "react-icons/go";
import Pagination from "../Reusable_Components/Pagination";
import { RECORDS_PER_PAGE } from "../../Config";
import TableComponent from "../Reusable_Components/TableComponent";
import Footer from "../Login/Footer";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import UploadComponent from '../Reusable_Components/UploadComponent';
import { eleminateSpacesAtStartOfInputField, processAndValidateEmail,
    checkUniqueness, toTitleCase, validateValue, formatNumericparseFloatInput, formatToWholeNumber
 } from "../Reusable_Components/ReusableFunctions";
import {facilityManagerGetAllAssets, handlePaginationLoaderTrue, getAssetStatusList, 
    getAssetCategoryList, getAssetTypeList, creatingNewAsset, updatingExistingAsset, getAllCompaniesListDropdown, createNewAmc, getAmcCategory, getAmcRenewalPeriod,
    commercialHeadGetBillingEntities, getAmcForAsset,getSpicificAmcDetails,CONTRACT_API_LOADER_TRIGGER_TRUE, CREATE_NEW_AMC_FAILURE, 
    CREATE_NEW_AMC_SUCCESS,getAllForms, CREATING_NEW_ASSET_SUCCESS, uploadDocumentForAsset, getAllDocumentsForAsset,
    facilityManagerGetAllAssetsDropdown, uploadDocumentForAmc, getAllDocumentsForAmc
} from '../../Redux/Actions/Actions'
import { useDispatch,useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import axios from "axios";
import { base_url } from "../../utilities";
import Modal from 'react-bootstrap/Modal'; 
import { set } from "date-fns";
import ViewForm from '../FormBuilder/ViewForm';
import Loader from '../Reusable_Components/loader';
import AlertPopUp from "../Reusable_Components/Alert";
import { error } from "pdf-lib";

export default function FacilityManagerAssetsList() {
    
    const dispatch = useDispatch();
    const error = useSelector(state => state.error)

    const contractApiLoader = useSelector(state => state.contractApiLoader)
    const getAllAssetsListDropdown = useSelector(state => state.getAllAssetsListDropdown)
    
    useEffect(() => {
        dispatch(facilityManagerGetAllAssets({ facility_id: localStorage.getItem("selected_facility_id"), serach_text: searchText, items_per_page: RECORDS_PER_PAGE, page_number: 1 }))
        dispatch(getAllCompaniesListDropdown())
        dispatch(getAmcCategory())
        dispatch(getAmcRenewalPeriod())
        dispatch(commercialHeadGetBillingEntities())
        dispatch(getAssetStatusList());
        dispatch(getAssetCategoryList());
        dispatch(getAssetTypeList());
        dispatch(getAllForms())
        dispatch(facilityManagerGetAllAssetsDropdown())
    }, [dispatch])

    useEffect (() => {
        if(error){
            setAlertShow(true)
            setAlertType('error')
            setAlertMessage('Error')
            setAlertValue(error.response.data.message)
            setAlertButtons([
                {
                    name: 'Close', action: () => {
                        setAlertShow(false)
                        backfunction()
                        amcBackFunction()
                        dispatch({
                            type: CREATE_NEW_AMC_FAILURE,
                            payload: null
                        })
                    }
                },
            ])
        }
    }, [error])

    const [alertShow, setAlertShow] = useState(false)
    const [alertType, setAlertType] = useState('')
    const [alertMessage, setAlertMessage] = useState('')
    const [alertValue, setAlertValue] = useState('')
    const [alertButtons, setAlertButtons] = useState([])




    const formsList = useSelector(state => state.formsList)
    const billingEntities = useSelector(state => state.billingEntities)

    const amcRenewalList = useSelector(state => state.amcRenewalList)
    const amcCategoryList = useSelector(state => state.amcCategoryList)

    const allAmcForAsset = useSelector(state => state.allAmcForAsset)
    
    const getAllCompaniesForDropdown = useSelector(state => state.getAllCompaniesForDropdown)
    
    const [activeTab, setActiveTab] = useState('1');

    const creatingNewAssetResponse = useSelector(state => state.creatingNewAssetResponse)
    // Function to handle the next button click

  // Function to handle the back button click
  const handleBack = () => {
    switch (activeTab) {
      case '4':
        setActiveTab('3');
        break;
      case '3':
        setActiveTab('2');
        break;
      case '2':
        setActiveTab('1');
        break;
      default:
        dispatch(facilityManagerGetAllAssets({ facility_id: localStorage.getItem("selected_facility_id"), serach_text: searchText, items_per_page: RECORDS_PER_PAGE, page_number: 1 }))
        backfunction();
        setCurrentPage(1);
    }
  };


    
  const [searchText, setSearchText] = useState("");
  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
    // if(searchText.length >= 3){
        dispatch(facilityManagerGetAllAssets({ facility_id: localStorage.getItem("selected_facility_id"), serach_text: e.target.value, items_per_page: RECORDS_PER_PAGE, page_number: 1 }))
    // }
  }
  const currentPaginationPage = useSelector(state => state.allAssetsList?.info?.page_number);
  const currentPaginationPageAmc = useSelector(state => state.allAmcForAsset?.info?.page_number);
  const [currentPage, setCurrentPage] = useState(1);
  const [assetName, setAssetName] = useState("");
  const [viewClicked, setViewClicked] = useState(false);
  const [selectVendorSupplier, setSelectVendorSupplier] = useState("");

  const [selectServiceProvider, setSelectServiceProvider] = useState("");

  const getServiceProviderDetails = (id) => {
    dispatch({
        type: CONTRACT_API_LOADER_TRIGGER_TRUE,
        payload: true
    })
    axios.get(base_url + "facilities/companies?filter_condition=%7B%22company_id%22%3A%20%22" + id + "%22%7D")
    .then((response) => {
        if (response?.data?.data[0] === 'error') {
            dispatch({
                type: CONTRACT_API_LOADER_TRIGGER_TRUE,
                payload: false
            })
        }
        else {
            const details = response.data.data[0]
            setCompanyNameServiceProvider(details.company_name)
            setPanNameServiceProvider(details.pan_number)
            setGstNumberServiceProvider(details.gst_number)
            setCinNumberServiceProvider(details.cin)
            setRegisteredStreetAddressServiceProvider(details.registered_address.street_address_1)
            setRegisteredStreetAddressLane2ServiceProvider(details.registered_address.street_address_2)
            setRegisteredCityServiceProvider(details.registered_address.city)
            setRegisteredStateProvinceServiceProvider(details.registered_address.state)
            setRegisteredPostalCodeServiceProvider(details.registered_address.postal_code)
            setBillingStreetAddressServiceProvider(details.billing_address.street_address_1)
            setBillingStreetAddressLane2ServiceProvider(details.billing_address.street_address_2)
            setBillingCityServiceProvider(details.billing_address.city)
            setBillingStateProvinceServiceProvider(details.billing_address.state)
            setBillingPostalCodeServiceProvider(details.billing_address.postal_code)
            setShippingStreetAddressServiceProvider(details.shipping_address.street_address_1)
            setShippingStreetAddressLane2ServiceProvider(details.shipping_address.street_address_2)
            setShippingCityServiceProvider(details.shipping_address.city)
            setShippingStateProvinceServiceProvider(details.shipping_address.state)
            setShippingPostalCodeServiceProvider(details.shipping_address.postal_code)
            setPrimaryContactNumberServiceProvider(details.primary_contact)
            setPrimaryEmailServiceProvider(details.primary_contact_email)
            setPrimaryContactNameServiceProvider(details.primary_contact_name)
            setListOfContactsServiceProvider(details.contacts)

            dispatch({
                type: CONTRACT_API_LOADER_TRIGGER_TRUE,
                payload: false
            })

        }
    }
    )
    .catch((error) => {
        // setErrorValue(error?.response?.data.message);

        setAlertShow(true)
        setAlertType('error')
        setAlertMessage('Error')
        setAlertValue(error.response.data.message)
        setAlertButtons([{
            name: 'Close', action: () => {

                setAlertShow(false)
            }
        }])
        dispatch({
            type: CONTRACT_API_LOADER_TRIGGER_TRUE,
            payload: false
        })
    });

  }

  const handleServiceProviderChange = (e) => {
    dispatch({
        type: CONTRACT_API_LOADER_TRIGGER_TRUE,
        payload: true
    })
    setSelectServiceProvider(e.target.value);
        axios.get(base_url + "facilities/companies?filter_condition=%7B%22company_id%22%3A%20%22" + e.target.value + "%22%7D")
    .then((response) => {
        if (response?.data?.data[0] === 'error') {
            dispatch({
                type: CONTRACT_API_LOADER_TRIGGER_TRUE,
                payload: false,
            });
        }
        else {
            const details = response.data.data[0]
            setCompanyNameServiceProvider(details.company_name)
            setPanNameServiceProvider(details.pan_number)
            setGstNumberServiceProvider(details.gst_number)
            setCinNumberServiceProvider(details.cin)
            setRegisteredStreetAddressServiceProvider(details.registered_address.street_address_1)
            setRegisteredStreetAddressLane2ServiceProvider(details.registered_address.street_address_2)
            setRegisteredCityServiceProvider(details.registered_address.city)
            setRegisteredStateProvinceServiceProvider(details.registered_address.state)
            setRegisteredPostalCodeServiceProvider(details.registered_address.postal_code)
            setBillingStreetAddressServiceProvider(details.billing_address.street_address_1)
            setBillingStreetAddressLane2ServiceProvider(details.billing_address.street_address_2)
            setBillingCityServiceProvider(details.billing_address.city)
            setBillingStateProvinceServiceProvider(details.billing_address.state)
            setBillingPostalCodeServiceProvider(details.billing_address.postal_code)
            setShippingStreetAddressServiceProvider(details.shipping_address.street_address_1)
            setShippingStreetAddressLane2ServiceProvider(details.shipping_address.street_address_2)
            setShippingCityServiceProvider(details.shipping_address.city)
            setShippingStateProvinceServiceProvider(details.shipping_address.state)
            setShippingPostalCodeServiceProvider(details.shipping_address.postal_code)
            setPrimaryContactNumberServiceProvider(details.primary_contact)
            setPrimaryEmailServiceProvider(details.primary_contact_email)
            setPrimaryContactNameServiceProvider(details.primary_contact_name)
            setListOfContactsServiceProvider(details.contacts)

            dispatch({
                type: CONTRACT_API_LOADER_TRIGGER_TRUE,
                payload: false,
            });
            
        }
    }
    )
    .catch((error) => {
        // setErrorValue(error?.response?.data.message);

        setAlertShow(true)
        setAlertType('error')
        setAlertMessage('Error')
        setAlertValue(error.response.data.message)
        setAlertButtons([{
            name: 'Close', action: () => {
                setAlertShow(false)
            }
        }])
        // setIsLoading(false)
        dispatch({
            type: CONTRACT_API_LOADER_TRIGGER_TRUE,
            payload: false,
        });
    });
  }

  const getVenderSupplierDetails = (id) => {
    dispatch({
        type: CONTRACT_API_LOADER_TRIGGER_TRUE,
        payload: true,
    });
    axios.get(base_url + "facilities/companies?filter_condition=%7B%22company_id%22%3A%20%22" + id + "%22%7D")
    .then((response) => {
        if (response?.data?.data[0] === 'error') {
            dispatch({
                type: CONTRACT_API_LOADER_TRIGGER_TRUE,
                payload: false,
            });
        }
        else {
            const details = response?.data?.data[0]
            setCompanyNameVendor(details?.company_name)
            setPanNameVendor(details?.pan_number)
            setGstNumberVendor(details?.gst_number)
            setCinNumberVendor(details?.cin)
            setRegisteredStreetAddressVendor(details?.registered_address?.street_address_1)
            setRegisteredStreetAddressLane2Vendor(details?.registered_address?.street_address_2)
            setRegisteredCityVendor(details?.registered_address?.city)
            setRegisteredStateProvinceVendor(details?.registered_address?.state)
            setRegisteredPostalCodeVendor(details?.registered_address?.postal_code)
            setBillingStreetAddressVendor(details?.billing_address?.street_address_1)
            setBillingStreetAddressLane2Vendor(details?.billing_address?.street_address_2)
            setBillingCityVendor(details?.billing_address?.city)
            setBillingStateProvinceVendor(details?.billing_address?.state)
            setBillingPostalCodeVendor(details?.billing_address?.postal_code)
            setShippingStreetAddressVendor(details?.shipping_address?.street_address_1)
            setShippingStreetAddressLane2Vendor(details?.shipping_address?.street_address_2)
            setShippingCityVendor(details?.shipping_address?.city)
            setShippingStateProvinceVendor(details?.shipping_address?.state)
            setShippingPostalCodeVendor(details?.shipping_address?.postal_code)
            setVendorContactNumber(details?.primary_contact)
            setVendorEmail(details?.primary_contact_email)
            setVendorName(details?.primary_contact_name)
            setListOfContacts(details?.contacts)
            dispatch({
                type: CONTRACT_API_LOADER_TRIGGER_TRUE,
                payload: false,
            });
            
        }
    }
    )
    .catch((error) => {
        // setErrorValue(error?.response?.data.message);

        setAlertShow(true)
        setAlertType('error')
        setAlertMessage('Error')
        setAlertValue(error?.response?.data.message)
        setAlertButtons([{
            name: 'Close', action: () => {
                setAlertShow(false)
            }
        }])
        // setIsLoading(false)
        dispatch({
            type: CONTRACT_API_LOADER_TRIGGER_TRUE,
            payload: false,
        });
    });

  }

    const handleVendorSupplierChnge = (e) => {
        dispatch({
            type: CONTRACT_API_LOADER_TRIGGER_TRUE,
            payload: true,
        });
        setSelectVendorSupplier(e.target.value);
        axios.get(base_url + "facilities/companies?filter_condition=%7B%22company_id%22%3A%20%22" + e.target.value + "%22%7D")
    .then((response) => {
        if (response?.data?.data[0] === 'error') {
            dispatch({
                type: CONTRACT_API_LOADER_TRIGGER_TRUE,
                payload: false,
            });
        }
        else {
            const details = response?.data?.data[0]
            setCompanyNameVendor(details?.company_name)
            setPanNameVendor(details?.pan_number)
            setGstNumberVendor(details?.gst_number)
            setCinNumberVendor(details?.cin)
            setRegisteredStreetAddressVendor(details?.registered_address?.street_address_1)
            setRegisteredStreetAddressLane2Vendor(details?.registered_address?.street_address_2)
            setRegisteredCityVendor(details?.registered_address?.city)
            setRegisteredStateProvinceVendor(details?.registered_address?.state)
            setRegisteredPostalCodeVendor(details?.registered_address?.postal_code)
            setBillingStreetAddressVendor(details?.billing_address?.street_address_1)
            setBillingStreetAddressLane2Vendor(details?.billing_address?.street_address_2)
            setBillingCityVendor(details?.billing_address?.city)
            setBillingStateProvinceVendor(details?.billing_address?.state)
            setBillingPostalCodeVendor(details?.billing_address?.postal_code)
            setShippingStreetAddressVendor(details?.shipping_address?.street_address_1)
            setShippingStreetAddressLane2Vendor(details?.shipping_address?.street_address_2)
            setShippingCityVendor(details?.shipping_address?.city)
            setShippingStateProvinceVendor(details?.shipping_address?.state)
            setShippingPostalCodeVendor(details?.shipping_address?.postal_code)
            setVendorContactNumber(details?.primary_contact)
            setVendorEmail(details?.primary_contact_email)
            setVendorName(details?.primary_contact_name)
            setListOfContacts(details?.contacts)
            dispatch({
                type: CONTRACT_API_LOADER_TRIGGER_TRUE,
                payload: false,
            });
            

            
        }
    }
    )
    .catch((error) => {
        // setErrorValue(error?.response?.data.message);

        setAlertShow(true)
        setAlertType('error')
        setAlertMessage('Error')
        setAlertValue(error?.response?.data.message)
        setAlertButtons([{
            name: 'Close', action: () => {
                setAlertShow(false)
            }
        }])
        // setIsLoading(false)
        dispatch({
            type: CONTRACT_API_LOADER_TRIGGER_TRUE,
            payload: false,
        });
    });
    }

  const allAssetsListTableData = useSelector(state => state.allAssetsList?.data)
  const totalRecords = useSelector((state) => state.allAssetsList?.info?.total_no_of_records);
  const [isAssetNameValid, setIsAssetNameValid] = useState(true);
  const [isAssetNameUnique, setIsAssetNameUnique] = useState(true);

  const handleAssetNameChange = (e) => {
    let value = e.target.value;
    value = eleminateSpacesAtStartOfInputField(value);
        // if (validateValue(value.charAt(0))) {
        //     setContractName(value);
    const lengthValid = value.length >= 3 && value.length <= 200;
    if(value.length === 0){
        setIsAssetNameValid(true);
    }
    else{
    setIsAssetNameValid(lengthValid);
    const isNameUnique = getAllAssetsListDropdown.some((asset) => asset.asset_name === value);
    setIsAssetNameUnique(!isNameUnique || selectedAssetForAction?.asset_name.toLowerCase() === value.toLowerCase());
    }
    setAssetName(value);
  };

 

  const handledPaginationNextClicked = () => {
    // Define the functionality for handling the next pagination click
    handlePaginationLoaderTrue()
    dispatch(facilityManagerGetAllAssets({ facility_id: localStorage.getItem("selected_facility_id"), serach_text: searchText, items_per_page: RECORDS_PER_PAGE, page_number: currentPaginationPage + 1 }))
  };

  const handledPaginationPrevClicked = () => {
    // Define the functionality for handling the previous pagination click
    dispatch(facilityManagerGetAllAssets({ facility_id: localStorage.getItem("selected_facility_id"), serach_text: searchText, items_per_page: RECORDS_PER_PAGE, page_number: currentPaginationPage - 1 }))

  };

  const handledPaginationNextClickedAmc = () => {
    // Define the functionality for handling the next pagination click
    handlePaginationLoaderTrue()
    dispatch(getAmcForAsset(
        {
            asset_id: creatingNewAssetResponse|| focusedAssetId,
            page_number: currentPaginationPageAmc + 1,
            items_per_page: RECORDS_PER_PAGE,

        }
    ))
  };

  const handledPaginationPrevClickedAmc = () => {
    // Define the functionality for handling the previous pagination click
    handlePaginationLoaderTrue()

    dispatch(getAmcForAsset(
        {
            asset_id: creatingNewAssetResponse|| focusedAssetId,
            page_number: currentPaginationPageAmc - 1,
            items_per_page: RECORDS_PER_PAGE,

        }
    ))
  };

  

  const allAssetStatusList = useSelector(state => state.allAssetStatusList)

  const allAssetCategoryList = useSelector(state => state.allAssetCategoryList)

  const allAssetTypeList = useSelector(state => state.allAssetTypeList)

  const facilitiesList = useSelector(state => state.facilitiesListForNav)


  const handleAddNewAsset = () => {
    dispatch(getAssetStatusList());
    dispatch(getAssetCategoryList());
    dispatch(getAssetTypeList());
    setCurrentPage(2);
  }

//   ======================= current page 2 fields and functions ===============================

// =================================== Asset Details ==================================

  const [facilityName, setFacilityName] = useState("");
  const [spaceName, setSpaceName] = useState("");
    const [assetCategory, setAssetCategory] = useState("");
    const [assetType, setAssetType] = useState("");
    const [assetNumber, setAssetNumber] = useState("");
    const [installationDate, setInstallationDate] = useState("");
    const [purchaseDate, setPurchaseDate] = useState("");
    const [brand, setBrand] = useState("");
    const [status, setStatus] = useState("");
    const [description, setDescription] = useState("");
    const [newAssetCategory, setNewAssetCategory] = useState("");

    const [isSpaceNameValid, setIsSpaceNameValid] = useState(true);

    const handleNewAssetCategoryChange = (e) => {
        setNewAssetCategory(e.target.value);
        };

    const handleFacilityNameChange = (e) => {
        setFacilityName(e.target.value);
      };
      
    const handleSpaceNameChange = (e) => {
        const value = e.target.value;
        const trimmedValue = eleminateSpacesAtStartOfInputField(value);
        const regex = /^[a-zA-Z0-9.,/\-_ !@#$%^&*():;+=<>?{}[\]~]*$/;
        if (regex.test(trimmedValue) || trimmedValue === '') {
            if (trimmedValue.length !== 0 && (trimmedValue.length < 3 || trimmedValue.length > 200)) {
                setIsSpaceNameValid(false);
            } else {
                setIsSpaceNameValid(true);
            }
            setSpaceName(trimmedValue);
        }
    };
      
      const handleAssetCategoryChange = (e) => {
        setAssetCategory(e.target.value);
      };
      
      const handleAssetTypeChange = (e) => {
        setAssetType(e.target.value);
      };
      const [isAssetNumberValid, setIsAssetNumberValid] = useState(true);
      const [isAssetNumberUnique, setIsAssetNumberUnique] = useState(true);
      
      const handleAssetNumberChange = (e) => {
        let input = e.target.value;
        input = input.replace(/[^a-zA-Z0-9./\\\-_]+/g, '');;
        const lengthValid = input.length === 0 || input.length >= 3 && input.length <= 200;

        // const isReplicaValid = input === selectedContractNumber;

        setAssetNumber(input); // Changed from value to input
        if(input.length === 0){
            setIsAssetNumberValid(true);
        }  
        else{
        setIsAssetNumberValid(lengthValid);
        const isNumberUnique = getAllAssetsListDropdown.some((asset) => asset.asset_number === input);
        setIsAssetNumberUnique(!isNumberUnique || (selectedAssetForAction?.asset_number).toLowerCase() === input.toLowerCase());
        }
        // const notUnique = checkUniqueness(contractNumbers, selectedContractNumber, input)
        // const isDuplicateNumber = contractNumbers.some((contract_number) => contract_number === input);
        // setDuplicateContractNumberError(notUnique);
        
      };
      
      const handleInstallationDateChange = (e) => {
        setInstallationDate(e.target.value);
        if(purchaseDate > e.target.value){
            setPurchaseDate(e.target.value);
        }
      };
      
      const handlePurchaseDateChange = (e) => {
        setPurchaseDate(e.target.value);
      };

      const [isBrandValid, setIsBrandValid] = useState(true);
      
      const handleBrandChange = (e) => {
        let value = e.target.value;
        value = eleminateSpacesAtStartOfInputField(value);
            // if (validateValue(value.charAt(0))) {
            //     setContractName(value);
        const lengthValid = value.length >= 3 && value.length <= 200;
        if(value.length === 0){
            setIsBrandValid(true);
        }
        else{
            setIsBrandValid(lengthValid);
        }
        
                //const notUnique = checkUniqueness(contractNames, selectedContractName, value)
                // const isReplicaValid = value.toLowerCase() === selectedContractName.toLowerCase();
                // const isDuplicateName = contractNames.some((contract_name) => contract_name.toLowerCase() === value.toLowerCase());
                //setDuplicateContractNameError(notUnique);
        setBrand(value);

      };
      
      const handleStatusChange = (e) => {
        setStatus(e.target.value);
      };
      
      const handleDescriptionChange = (e) => {
        let value = e.target.value;
        value = eleminateSpacesAtStartOfInputField(value);
        setDescription(value);
      };

// =================================== Asset Details ==================================

// =================================== Purchase Details ==================================

    const [purchaseOrderNo, setPurchaseOrderNo] = useState("");
    const [assetCost, setAssetCost] = useState("");
    const [vendorName, setVendorName] = useState("");
    const [vendorContactNumber, setVendorContactNumber] = useState("");
    const [vendorEmail, setVendorEmail] = useState("");
    const [gstNumberVendor, setGstNumberVendor] = useState('');
    const [cinNumberVendor, setCinNumberVendor] = useState('');

    const [companyNameVendor, setCompanyNameVendor] = useState("");
    const [panNameVendor, setPanNameVendor] = useState("");
    const [registeredStreetAddressVendor, setRegisteredStreetAddressVendor] = useState("");
    const [registeredStreetAddressLane2Vendor, setRegisteredStreetAddressLane2Vendor] = useState("");
    const [registeredCityVendor, setRegisteredCityVendor] = useState("");
    const [registeredStateProvinceVendor, setRegisteredStateProvinceVendor] = useState("");
    const [registeredPostalCodeVendor, setRegisteredPostalCodeVendor] = useState("");

    const [billingStreetAddressVendor, setBillingStreetAddressVendor] = useState("");
    const [billingStreetAddressLane2Vendor, setBillingStreetAddressLane2Vendor] = useState("");
    const [billingCityVendor, setBillingCityVendor] = useState("");
    const [billingStateProvinceVendor, setBillingStateProvinceVendor] = useState("");
    const [billingPostalCodeVendor, setBillingPostalCodeVendor] = useState("");

    const [shippingStreetAddressVendor, setShippingStreetAddressVendor] = useState("");
    const [shippingStreetAddressLane2Vendor, setShippingStreetAddressLane2Vendor] = useState("");
    const [shippingCityVendor, setShippingCityVendor] = useState("");
    const [shippingStateProvinceVendor, setShippingStateProvinceVendor] = useState("");
    const [shippingPostalCodeVendor, setShippingPostalCodeVendor] = useState("");

    const [isPurchaseOrderNumberValid ,setIsPurchaseOrderNumberValid] = useState(true);
    


    const handlePurchaseOrderChange = (e) => {
        let input = e.target.value;
        input = input.replace(/[^a-zA-Z0-9./\\\-_]+/g, '');;
        const lengthValid = input.length === 0 || input.length >= 3 && input.length <= 200;

        // const isReplicaValid = input === selectedContractNumber;

        setPurchaseOrderNo(input); // Changed from value to input
        if(input.length === 0){
            setIsPurchaseOrderNumberValid(true);
        }  
        else{
        setIsPurchaseOrderNumberValid(lengthValid);
        }
        
    };
    
    const handleAssetCostChange = (e) => {

        const input = e.target.value;
        const formattedInput = formatNumericparseFloatInput(input);
        setAssetCost(parseFloat(formattedInput));
        
    };
    





    const [listOfContacts, setListOfContacts] = useState([{
        name: "",
        phone_number: "",
        email: "",
        designation: "",
    }])

   




// =================================== Purchase Details ==================================


    //   ======================= current page 2 fields and functions ===============================

    const [addAmcShowModal, setAddAmcShowModal] = useState(false);
    const [amcStartDate, setAmcStartDate] = useState("");
    const [amcEndDate, setAmcEndDate] = useState("");
    const [amcRenewedByDate, setAmcRenewedByDate] = useState("");

    const handleAmcStartDateChange = (e) => {
        setAmcStartDate(e.target.value);
        if(amcEndDate < e.target.value){
            setAmcEndDate("");
        }
        if(amcRenewedByDate < e.target.value){
            setAmcRenewedByDate("");
        }
    }

    const handleAmcEndDateChange = (e) => {
        setAmcEndDate(e.target.value);
    }

    const handleAmcRenewedByDateChange = (e) => {
        setAmcRenewedByDate(e.target.value);
    }

    const [amcNumberOfServices, setAmcNumberOfServices] = useState("");
    const [amcCost, setAmcCost] = useState("");


    const handleAmcNumberOfServicesChange = (e) => {
        const input = e.target.value;
        const formattedInput = formatToWholeNumber(input);

        setAmcNumberOfServices(parseInt(formattedInput));
    }

    const handleAmcCostChange = (e) => {
        const input = e.target.value;
        const formattedInput = formatNumericparseFloatInput(input);
        setAmcCost(parseFloat(formattedInput));
    }

    const [primaryContactNameServiceProvider, setPrimaryContactNameServiceProvider] = useState("");
    const [primaryContactNumberServiceProvider, setPrimaryContactNumberServiceProvider] = useState("");
    const [primaryEmailServiceProvider, setPrimaryEmailServiceProvider] = useState("");
    const [primaryContactDesignationServiceProvider, setPrimaryContactDesignationServiceProvider] = useState("");
    const [gstNumberServiceProvider, setGstNumberServiceProvider] = useState('');
    const [cinNumberServiceProvider, setCinNumberServiceProvider] = useState('');
    const [companyNameServiceProvider, setCompanyNameServiceProvider] = useState("");
    const [panNameServiceProvider, setPanNameServiceProvider] = useState("");
    const [registeredStreetAddressServiceProvider, setRegisteredStreetAddressServiceProvider] = useState("");
    const [registeredStreetAddressLane2ServiceProvider, setRegisteredStreetAddressLane2ServiceProvider] = useState("");
    const [registeredCityServiceProvider, setRegisteredCityServiceProvider] = useState("");
    const [registeredStateProvinceServiceProvider, setRegisteredStateProvinceServiceProvider] = useState("");
    const [registeredPostalCodeServiceProvider, setRegisteredPostalCodeServiceProvider] = useState("");

    const [billingStreetAddressServiceProvider, setBillingStreetAddressServiceProvider] = useState("");
    const [billingStreetAddressLane2ServiceProvider, setBillingStreetAddressLane2ServiceProvider] = useState("");
    const [billingCityServiceProvider, setBillingCityServiceProvider] = useState("");
    const [billingStateProvinceServiceProvider, setBillingStateProvinceServiceProvider] = useState("");
    const [billingPostalCodeServiceProvider, setBillingPostalCodeServiceProvider] = useState("");

    const [shippingStreetAddressServiceProvider, setShippingStreetAddressServiceProvider] = useState("");
    const [shippingStreetAddressLane2ServiceProvider, setShippingStreetAddressLane2ServiceProvider] = useState("");
    const [shippingCityServiceProvider, setShippingCityServiceProvider] = useState("");
    const [shippingStateProvinceServiceProvider, setShippingStateProvinceServiceProvider] = useState("");
    const [shippingPostalCodeServiceProvider, setShippingPostalCodeServiceProvider] = useState("");

    const [listOfContactsServiceProvider, setListOfContactsServiceProvider] = useState([{
        name: "",
        phone_number: "",
        email: "",
        designation: "",
    }])

    const [amcCategory, setAmcCategory] = useState("");
    const [amcRenewalPeriod, setAmcRenewalPeriod] = useState("");

    const handleAmcRenewalPeriodChange = (e) => {
        setAmcRenewalPeriod(e.target.value)
    }

    const handleAmcCategoryChange = (e) => {
        setAmcCategory(e.target.value)
    }

    const [billingEntity, setBillingEntity] = useState("");

    const handleBillingEntityChange = (e) => {
        setBillingEntity(e.target.value)
    }

    const [listOfAmcForAsset, setListOfAmcForAsset] = useState([])




    const handleCreateNewAmcSubmit = () => {
         let currentUrl = "";
        currentUrl = `facilities/amc`
        dispatch({
            type: CONTRACT_API_LOADER_TRIGGER_TRUE,
            payload: true,
        });
        if(!editAmcClicked){

            const newAmc = {
                asset_id: creatingNewAssetResponse || focusedAssetId,
                amc_start_date: amcStartDate,
                amc_end_date: amcEndDate,
                to_be_renewed_by: amcRenewedByDate,
                no_of_services: amcNumberOfServices,
                amc_cost: amcCost,
                amc_category: amcCategory,
                renewal_period: amcRenewalPeriod
    
            }
            axios.post(base_url + currentUrl, newAmc)
            .then((response) => {
                if (response?.data?.data[0] === 'error') {
                    dispatch({
                        type: CREATE_NEW_AMC_FAILURE,
                        payload: response,
                    });
                    dispatch({
                        type: CONTRACT_API_LOADER_TRIGGER_TRUE,
                        payload: false,
                    });
                }
                else {
                    dispatch({
                        type: CREATE_NEW_AMC_SUCCESS,
                        payload: response.data.data,
                    });
                    dispatch({
                        type: CONTRACT_API_LOADER_TRIGGER_TRUE,
                        payload: false,
                    });
                    axios.post(base_url + "facilities/serviceproviders", {
                        "amc_id": response?.data?.data?.amc_id,
                        "company_id": selectServiceProvider
                    })
                    .then((response) => {
                        console.log(response)
                    }
                    )
                    .catch((error) => {
                        console.log(error)
                    }
                    );
                    

                    dispatch(getAmcForAsset({
                        asset_id: creatingNewAssetResponse|| focusedAssetId,
                        page_number: 1,
                        items_per_page: RECORDS_PER_PAGE,
                    }))
                    amcBackFunction();
                    
                }
            })
            .catch((error) => {
                dispatch({
                    type: CREATE_NEW_AMC_FAILURE,
                    payload: error,
                });
                dispatch({
                    type: CONTRACT_API_LOADER_TRIGGER_TRUE,
                    payload: false,
                });
            });
        }
        else{
            const newAmc = {
                amc_id: selectedAmcId,
                asset_id: creatingNewAssetResponse || focusedAssetId,
                amc_start_date: amcStartDate,
                amc_end_date: amcEndDate,
                to_be_renewed_by: amcRenewedByDate,
                no_of_services: amcNumberOfServices,
                amc_cost: amcCost,
                amc_category: amcCategory,
                renewal_period: amcRenewalPeriod
    
            }
            axios.put(base_url + currentUrl, newAmc)
            .then((response) => {
                if (response?.data?.data[0] === 'error') {
                    dispatch({
                        type: CREATE_NEW_AMC_FAILURE,
                        payload: response,
                    });
                    dispatch({
                        type: CONTRACT_API_LOADER_TRIGGER_TRUE,
                        payload: false,
                    });
                }
                else {
                    dispatch({
                        type: CREATE_NEW_AMC_SUCCESS,
                        payload: response?.data?.data,
                    });
                    dispatch({
                        type: CONTRACT_API_LOADER_TRIGGER_TRUE,
                        payload: false,
                    });

                    dispatch(getAmcForAsset({
                        asset_id: creatingNewAssetResponse|| focusedAssetId,
                        page_number: 1,
                        items_per_page: RECORDS_PER_PAGE,
                    }))
                }
                amcBackFunction();
            })
            .catch((error) => {
                dispatch({
                    type: CREATE_NEW_AMC_FAILURE,
                    payload: error,
                });
                dispatch({
                    type: CONTRACT_API_LOADER_TRIGGER_TRUE,
                    payload: false,
                });
            });
        }
        

        
       
    }

    const [viewAmcClicked, setViewAmcClicked] = useState(false);
    const [editAmcClicked, setEditAmcClicked] = useState(false);
    const [selectedAmcId, setSelectedAmcId] = useState("");
    const documentsForAnAmc = useSelector(state => state.documentsForAnAmc)

    const handleViewAmcClicked = (data) => {
        setViewAmcClicked(true);
        setEditAmcClicked(false);
        dispatch(getAllDocumentsForAmc(data.amc_id))
        dispatch({
            type : CONTRACT_API_LOADER_TRIGGER_TRUE,
            payload : true
        })
        setSelectedAmcId(data.amc_id)
        dispatch({
            type: CONTRACT_API_LOADER_TRIGGER_TRUE,
            payload: true,
        });
        axios.get(base_url + `facilities/amc?filter_condition=%7B%22amc_id%22%3A%20%22${data?.amc_id}%22%7D`)
            .then((response) => {
                const amc = response?.data?.data[0];
                dispatch({
                    type : CONTRACT_API_LOADER_TRIGGER_TRUE,
                    payload : false,
                })
                // const data = assets.map((item) => item.asset_name) 
                setAddAmcShowModal(true)
                setAmcStartDate(amc?.amc_start_date)
                setAmcEndDate(amc?.amc_end_date)
                setAmcRenewedByDate(amc?.to_be_renewed_by)
                setAmcNumberOfServices(amc?.no_of_services)
                setAmcCost(amc?.amc_cost)
                setAmcCategory(amc?.amc_category)
                setAmcRenewalPeriod(amc?.renewal_period)
                setSelectServiceProvider(amc?.company_details?.company_id)
                getServiceProviderDetails(amc?.company_details?.company_id)
                dispatch({
                    type: CONTRACT_API_LOADER_TRIGGER_TRUE,
                    payload: false,
                });
        })
        .catch((error) => {
            setAlertShow(true)
            setAlertType('error')
            setAlertMessage('Error')
            setAlertValue(error?.response?.data.message)
            setAlertButtons([{
                name: 'Close', action: () => {
                        amcBackFunction()
                    setAlertShow(false)
                }
            }])
           dispatch({
            type : CONTRACT_API_LOADER_TRIGGER_TRUE,
            payload : false,
        })
        });


        //dispatch(getSpicificAmcDetails({amc_id: data.amc_id}))
        
    }

    const handleEditAmcClicked = (data) => {
        dispatch({
            type : CONTRACT_API_LOADER_TRIGGER_TRUE,
            payload : true
        })
        setEditAmcClicked(true);
        setViewAmcClicked(false);
        setSelectedAmcId(data.amc_id)
        dispatch(getAllDocumentsForAmc(data.amc_id))


        axios.get(base_url + `facilities/amc?filter_condition=%7B%22amc_id%22%3A%20%22${data.amc_id}%22%7D`)
            .then((response) => {
                const amc = response?.data?.data[0];
                dispatch({
                    type : CONTRACT_API_LOADER_TRIGGER_TRUE,
                    payload : false,
                })
                // const data = assets.map((item) => item.asset_name) 
                setAddAmcShowModal(true)
                setAmcStartDate(amc?.amc_start_date)
                setAmcEndDate(amc?.amc_end_date)
                setAmcRenewedByDate(amc?.to_be_renewed_by)
                setAmcNumberOfServices(amc?.no_of_services)
                setAmcCost(amc?.amc_cost)
                setAmcCategory(amc?.amc_category)
                setAmcRenewalPeriod(amc?.renewal_period)
                setSelectServiceProvider(amc?.company_details.company_id)
                getServiceProviderDetails(amc?.company_details.company_id)
        })
        .catch((error) => {
           setAlertShow(true)
              setAlertType('error')
                setAlertMessage('Error')
                setAlertValue(error?.response?.data.message)
                setAlertButtons([{
                    name: 'Close', action: () => {
                        amcBackFunction()
                        setAlertShow(false)
                    }
                }])
           dispatch({
            type : CONTRACT_API_LOADER_TRIGGER_TRUE,
            payload : false,
        })
        });
    }

    const handleAssetDetailsSave = () => {
        if(!creatingNewAssetResponse && !focusedAssetId){
            dispatch(creatingNewAsset(
                {   
                    facility_id: localStorage.getItem("selected_facility_id"), 
                    billing_entity: billingEntity,
                    asset_name: assetName.trim(),
                    space: spaceName.trim(),
                    asset_category: assetCategory,
                    asset_type: assetType, 
                    asset_number: assetNumber.trim(), 
                    installation_date: installationDate, 
                    brand_name: brand.trim(), 
                    status: status, 
                    description: description.trim(),
                    billing_entity_name: billingEntity,

                }))
                dispatch(facilityManagerGetAllAssetsDropdown())
                setActiveTab('2');
        }
        else{
            dispatch(updatingExistingAsset(
                {
                    asset_id: creatingNewAssetResponse||focusedAssetId,
                    facility_id: localStorage.getItem("selected_facility_id"), 
                    asset_name: assetName.trim(),
                    space: spaceName.trim(),
                    asset_category: assetCategory,
                    asset_type: assetType, 
                    asset_number: assetNumber,
                    installation_date: installationDate,
                    brand_name: brand.trim(), 
                    status: status, 
                    description: description.trim(),
                    purchase_order_number: purchaseOrderNo,
                    asset_cost: assetCost,
                    vendor_id: selectVendorSupplier,
                    purchase_date: purchaseDate,
                    billing_entity: billingEntity,
                    billing_entity_name: billingEntity,

                }
            ))
            dispatch(facilityManagerGetAllAssetsDropdown())

            setActiveTab('2');
        }
    }

    const handlePurchaseDetailsSave = () => {
        
        if(!focusedAssetId){
            dispatch(updatingExistingAsset(
                {
                    asset_id: creatingNewAssetResponse,
                    facility_id: localStorage.getItem("selected_facility_id"), 
                    asset_name: assetName,
                    space: spaceName,
                    asset_category: assetCategory,
                    asset_type: assetType, 
                    asset_number: assetNumber, 
                    installation_date: installationDate,
                    brand_name: brand, 
                    status: status, 
                    description: description,
                    purchase_order_number: purchaseOrderNo,
                    asset_cost: assetCost,
                    vendor_id: selectVendorSupplier,
                    purchase_date: purchaseDate,
                    billing_entity: billingEntity,
                    billing_entity_name: billingEntity,
                }
            ))
            setActiveTab('3');
            axios.post(base_url + "facilities/suppliers", {
                "asset_id": creatingNewAssetResponse || focusedAssetId,
                "company_id": selectVendorSupplier
            })
            .then((response) => {
                console.log(response)
               
            }
            )
            .catch((error) => {
                // setErrorValue(error?.response?.data.message);
    
                setAlertShow(true)
                setAlertType('error')
                setAlertMessage('Error')
                setAlertValue(error?.response?.data.message)
                setAlertButtons([{
                    name: 'Close', action: () => {
                        setAlertShow(false)
                    }
                }])
                // setIsLoading(false)
                
            }
            );
        }
        else{
            dispatch(updatingExistingAsset(
                {
                    asset_id: focusedAssetId,
                    facility_id: localStorage.getItem("selected_facility_id"), 
                    asset_name: assetName,
                    space: spaceName,
                    asset_category: assetCategory,
                    asset_type: assetType, 
                    asset_number: assetNumber, 
                    installation_date: installationDate,
                    brand_name: brand, 
                    status: status, 
                    description: description,
                    purchase_order_number: purchaseOrderNo,
                    asset_cost: assetCost,
                    vendor_id: selectVendorSupplier,
                    purchase_date: purchaseDate,
                    billing_entity: billingEntity,
                    billing_entity_name: billingEntity,
                }
            ))
            setActiveTab('3');
            axios.post(base_url + "facilities/suppliers", {
                "asset_id": creatingNewAssetResponse || focusedAssetId,
                "company_id": selectVendorSupplier
            })
            .then((response) => {
                console.log(response)
                
            }
            )
            .catch((error) => {
                // setErrorValue(error?.response?.data.message);
    
                setAlertShow(true)
                setAlertType('error')
                setAlertMessage('Error')
                setAlertValue(error?.response?.data.message)
                setAlertButtons([{
                    name: 'Close', action: () => {
                        setAlertShow(false)
                    }
                }])
                // setIsLoading(false)
                
            }
            );
        }
        
    }

    const convertDataForTable = (data) => {

        const updatedDataList = data.map(item => {
            if (item.form_details && item.form_details.length > 0) {
                const details = item.form_details[0]; // Assuming a single form_details object
                delete item.form_details; // Remove form_details array
                Object.assign(item, details); // Merge details into the main object
            }
            return item; // Return the updated object
        });

        return updatedDataList;

    }

    const handleAmcDetailsSave = () => {
        setActiveTab('4');
    
    }

    const handleCloseAssetCreationFlow = () => {
            

            setAlertShow(true)
            setAlertType('success')
            setAlertMessage('Success')
            setAlertValue(<>
            <p className="mb-3">Asset <strong>{assetName}</strong> created Successfully</p>
            </>)
            setAlertButtons([{
                name: 'Close', action: () => {
                    setAlertShow(false)
                    setCurrentPage(1)
                    backfunction()
                    amcBackFunction()
                    dispatch(facilityManagerGetAllAssets({ facility_id: localStorage.getItem("selected_facility_id"), serach_text: searchText, items_per_page: RECORDS_PER_PAGE, page_number: currentPaginationPage }))
                }
            }])

            dispatch({
                type: CREATING_NEW_ASSET_SUCCESS,
                payload: null,
            });
    }


    const handleSopFormSave = () => {
        dispatch({
            type: CONTRACT_API_LOADER_TRIGGER_TRUE,
            payload: true,
        });
        
        let sendData = [];

        if(checklistFormId){
            sendData.push({
                "asset_id": creatingNewAssetResponse || focusedAssetId,
                "form_id": checklistFormId,
                "form_type":"checklist"
            })
        }
        if(sopFormId){
            sendData.push({
                "asset_id": creatingNewAssetResponse || focusedAssetId,
                "form_id": sopFormId,
                "form_type":"sop"
            })
        }

        axios.post(base_url + "facilities/assets/assetsFormsRef", 
            {
                "forms_list": sendData
            }


        )
        .then((response) => {
           
            dispatch({
                type: CONTRACT_API_LOADER_TRIGGER_TRUE,
                payload: false,
            });

            setChecklistFormId("")
            setSopFormId("")

            axios.get(base_url + "facilities/assets/assetsFormsRef?filter_condition=%7B%22asset_id%22%3A%20%22"+(creatingNewAssetResponse || focusedAssetId)+"%22%7D")
                .then((response) => {
                   setMaintainanceHistoryData(convertDataForTable(response.data.data))
                    dispatch({
                        type: CONTRACT_API_LOADER_TRIGGER_TRUE,
                        payload: false,
                    });
                    
                }
                )
                .catch((error) => {
                    setAlertShow(true)
                    setAlertType('error')
                    setAlertMessage('Error')
                    setAlertValue(error?.response?.data.message)
                    setAlertButtons([{
                        name: 'Close', action: () => {
                            setAlertShow(false)
                        }
                    }])
                    dispatch({
                        type: CONTRACT_API_LOADER_TRIGGER_TRUE,
                        payload: false,
                    });
                }
                );
            
        }
        )
        .catch((error) => {
            setAlertShow(true)
            setAlertType('error')
            setAlertMessage('Error')
            setAlertValue(error?.response?.data.message)
            setAlertButtons([{
                name: 'Close', action: () => {
                    setAlertShow(false)
                }
            }])
            dispatch({
                type: CONTRACT_API_LOADER_TRIGGER_TRUE,
                payload: false,
            });
        }
        );

    }

    const [MaintainanceHistoryData, setMaintainanceHistoryData] = useState([]);

    const [focusedAssetId, setFocusedAssetId] = useState("");
    const [selectedAssetForAction, setSelectedAssetForAction] = useState(null);


    const handleViewActionClicked = (data) => {
        setViewClicked(true);
        dispatch(getAllDocumentsForAsset(data.asset_id))
        dispatch({
            type: CONTRACT_API_LOADER_TRIGGER_TRUE,
            payload: true,
        });
        axios.get(base_url + `facilities/assets?filter_condition=%7B%22asset_id%22%3A%20%22${data.asset_id}%22%7D`)
            .then((response) => {
                const assetInfo = response?.data?.data[0];
                dispatch({
                    type : CONTRACT_API_LOADER_TRIGGER_TRUE,
                    payload : false,
                })
                setSelectedAssetForAction(assetInfo)
                setFocusedAssetId(assetInfo?.asset_id)
                setAssetName(assetInfo?.asset_name)
                setSpaceName(assetInfo?.space)

                setAssetCategory(assetInfo?.asset_category)
                setAssetType(assetInfo?.asset_type)
                setAssetNumber(assetInfo?.asset_number)
                setInstallationDate(assetInfo?.installation_date)
                setPurchaseDate(assetInfo?.purchase_date)
                setBrand(assetInfo?.brand_name)
                setStatus(assetInfo?.status)
                setDescription(assetInfo?.description)
                setBillingEntity(assetInfo?.billing_entity)

                setPurchaseOrderNo(assetInfo?.purchase_order_number)
                setAssetCost(assetInfo?.asset_cost)
                setSelectVendorSupplier(assetInfo?.company_details?.company_id)
                getVenderSupplierDetails(assetInfo?.company_details?.company_id)

                setCurrentPage(2)
                setActiveTab('1')
                dispatch(getAmcForAsset({
                    asset_id: data.asset_id,
                    page_number: 1,
                    items_per_page: RECORDS_PER_PAGE,
                }));

                // axios.get(base_url + "facilities/assets/assetsFormsRef?filter_condition=%7B%22asset_id%22%3A%20%22"+data.asset_id+"%22%7D")
                // .then((response) => {
                //     setMaintainanceHistoryData(convertDataForTable(response.data.data))
                //     dispatch({
                //         type: CONTRACT_API_LOADER_TRIGGER_TRUE,
                //         payload: false,
                //     });
                    
                // }
                // )
                // .catch((error) => {
                //     setAlertShow(true)
                //     setAlertType('error')
                //     setAlertMessage('Error')
                //     setAlertValue(error?.response?.data?.message)
                //     setAlertButtons([{
                //         name: 'Close', action: () => {
                //             setAlertShow(false)
                //         }
                //     }])
                //     dispatch({
                //         type: CONTRACT_API_LOADER_TRIGGER_TRUE,
                //         payload: false,
                //     });
                // }
                // );


        })
        .catch((error) => {
          setAlertShow(true)
            setAlertType('error')
            setAlertMessage('Error')
            setAlertValue(error?.response?.data.message)
            setAlertButtons([{
                name: 'Close', action: () => {
                    // backfunction()
                    //     amcBackFunction()
                    setAlertShow(false)
                }
            }])
           dispatch({
            type : CONTRACT_API_LOADER_TRIGGER_TRUE,
            payload : false,
        })
        });
      };

      const [editClicked, setEditClicked] = useState(false);

    const handleEditActionClicked = (data) => {
        dispatch(getAllDocumentsForAsset(data.asset_id))

        dispatch({
            type: CONTRACT_API_LOADER_TRIGGER_TRUE,
            payload: true,
        });
        setViewClicked(false);
        setEditClicked(true);
        axios.get(base_url + 
             `facilities/assets?filter_condition=%7B%22asset_id%22%3A%20%22${data.asset_id}%22%7D` 
        )
            .then((response) => {
                const assetInfo = response?.data?.data[0];
                setSelectedAssetForAction(assetInfo)
                dispatch({
                    type : CONTRACT_API_LOADER_TRIGGER_TRUE,
                    payload : false,
                })
                
                setFocusedAssetId(assetInfo?.asset_id)
                setAssetName(assetInfo?.asset_name)
                setSpaceName(assetInfo?.space)
                setAssetCategory(assetInfo?.asset_category)
                setAssetType(assetInfo?.asset_type)
                setAssetNumber(assetInfo?.asset_number)
                setInstallationDate(assetInfo?.installation_date)
                setPurchaseDate(assetInfo?.purchase_date)
                setBrand(assetInfo?.brand_name)
                setStatus(assetInfo?.status)
                setDescription(assetInfo?.description)
                setBillingEntity(assetInfo?.billing_entity)

                setPurchaseOrderNo(assetInfo?.purchase_order_number)
                setAssetCost(assetInfo?.asset_cost)
                setSelectVendorSupplier(assetInfo?.company_details?.company_id)
                getVenderSupplierDetails(assetInfo?.company_details?.company_id)

                setCurrentPage(2)
                setActiveTab('1')

                // axios.get(base_url + "facilities/assets/assetsFormsRef?filter_condition=%7B%22asset_id%22%3A%20%22"+data.asset_id+"%22%7D")
                // .then((response) => {
                //     setMaintainanceHistoryData(convertDataForTable(response.data.data))
                //     dispatch({
                //         type: CONTRACT_API_LOADER_TRIGGER_TRUE,
                //         payload: false,
                //     });
                    
                // }
                // )
                // .catch((error) => {
                //     setAlertShow(true)
                //     setAlertType('error')
                //     setAlertMessage('Error')
                //     setAlertValue(error?.response?.data.message)
                //     setAlertButtons([{
                //         name: 'Close', action: () => {
                //             setAlertShow(false)
                //         }
                //     }])
                //     dispatch({
                //         type: CONTRACT_API_LOADER_TRIGGER_TRUE,
                //         payload: false,
                //     });
                // }
                // );

        })
        .catch((error) => {
           setAlertShow(true)
            setAlertType('error')
            setAlertMessage('Error')
            setAlertValue(error?.response?.data.message)
            setAlertButtons([{
                name: 'Close', action: () => {
                    // backfunction()
                    //     amcBackFunction()
                    setAlertShow(false)
                }
            }])
           dispatch({
            type : CONTRACT_API_LOADER_TRIGGER_TRUE,
            payload : false,
        })
        });

        dispatch(getAmcForAsset({
            asset_id: data.asset_id,
            page_number: 1,
            items_per_page: RECORDS_PER_PAGE,
        }    
        ));



    }

    const backfunction = () => {
        setSpaceName("");
        setBillingEntity("");
        setAssetCategory("");
        setAssetType("");
        setAssetNumber("");
        setInstallationDate("");
        setPurchaseDate("");
        setBrand("");
        setStatus("");
        setDescription("");
        setPurchaseOrderNo("");
        setAssetCost("");
        setSelectVendorSupplier("");
        setVendorName("");
        setVendorContactNumber("");
        setVendorEmail("");
        setGstNumberVendor("");
        setCinNumberVendor("");
        setCompanyNameVendor("");
        setPanNameVendor("");
        setRegisteredStreetAddressVendor("");
        setRegisteredStreetAddressLane2Vendor("");
        setRegisteredCityVendor("");
        setRegisteredStateProvinceVendor("");
        setRegisteredPostalCodeVendor("");
        setBillingStreetAddressVendor("");
        setBillingStreetAddressLane2Vendor("");
        setBillingCityVendor("");
        setBillingStateProvinceVendor("");
        setBillingPostalCodeVendor("");
        setShippingStreetAddressVendor("");
        setShippingStreetAddressLane2Vendor("");
        setShippingCityVendor("");
        setShippingStateProvinceVendor("");
        setShippingPostalCodeVendor("");
        setListOfContacts([]);
        setFocusedAssetId("");
        setAssetName("");
        setViewClicked(false);
        setAddAmcShowModal(false);
        setEditClicked(false);
        setEditAmcClicked(false);
        setViewAmcClicked(false);
        setUploadedFiles(null);
        setDocumentListArray([]);
        setSelectedAssetForAction("");
        setActiveTab('1');
       


    }

    const amcBackFunction = () => {
        setAmcStartDate("")
        setAmcEndDate("")
        setAmcRenewedByDate("")
        setAmcRenewalPeriod("")
        setAmcNumberOfServices("")
        setAmcCost("")
        setAmcCategory("")
        setSelectServiceProvider("")
        setPrimaryContactDesignationServiceProvider("")
        setPrimaryContactNameServiceProvider("")
        setPrimaryContactNumberServiceProvider("")
        setPrimaryEmailServiceProvider("")
        setGstNumberServiceProvider("")
        setCinNumberServiceProvider("")
        setCompanyNameServiceProvider("")
        setPanNameServiceProvider("")
        setRegisteredStreetAddressServiceProvider("")
        setRegisteredStreetAddressLane2ServiceProvider("")
        setRegisteredCityServiceProvider("")
        setRegisteredStateProvinceServiceProvider("")
        setRegisteredPostalCodeServiceProvider("")

        setBillingStreetAddressServiceProvider("")
        setBillingStreetAddressLane2ServiceProvider("")
        setBillingCityServiceProvider("")
        setBillingStateProvinceServiceProvider("")
        setBillingPostalCodeServiceProvider("")

        setShippingStreetAddressServiceProvider("")
        setShippingStreetAddressLane2ServiceProvider("")
        setShippingCityServiceProvider("")
        setShippingStateProvinceServiceProvider("")
        setShippingPostalCodeServiceProvider("")
        setViewAmcClicked(false)
        setEditAmcClicked(false)
        setAddAmcShowModal(false)
        setSelectedAmcId("")
        setUploadedFilesAmc(null);
        setDocumentListArrayAmc([]);

    }

    const [checklistFormId, setChecklistFormId] = useState("");
    const [sopFormId, setSopFormId] = useState("");

    const handleChecklistFormChange = (e) => {
        setChecklistFormId(e.target.value)
    }

    const handleSopFormChange = (e) => {
        setSopFormId(e.target.value)
    }

    const [viewForm, setViewForm] = useState(null);

    const handleViewFormClicked = (id) => {
        const selectedFormData = formsList.find((form) => form.form_id === id);
        setViewForm(JSON.parse(selectedFormData.json_data));
    }

    const handleViewFormBackClicked = () => {
        setViewForm(null);
    }

    const handleNextClickedViewMode = () => {
        switch (activeTab) {
            case '1':
              setActiveTab('2');
              break;
            case '2':
              setActiveTab('3');
              break;
            case '3':
              setActiveTab('4');
              break;
            default:
              backfunction();
              setCurrentPage(1);
          }

    }

    const handleViewSpecificFormClicked = (data) => {
        const selectedFormData = data?.json_data;
        setViewForm(JSON.parse(selectedFormData));
    }


    // ========================= document upload functions ============================
    const [uploadedFiles, setUploadedFiles] = useState();
    const [uploadedFilesAmc, setUploadedFilesAmc] = useState();

    const [documentsListArray, setDocumentListArray] = useState([])
    const [documentsListArrayAmc, setDocumentListArrayAmc] = useState([])
    const documentsForAnAsset = useSelector((state) => state.documentsForAnAsset);
    const uploadDocumentForAssetResponse = useSelector((state) => state.uploadDocumentForAssetResponse);
    const uploadedDocumentsForAmcResponse = useSelector((state) => state.uploadedDocumentsForAmcResponse);

    const handleDocumentUpload = (index) => {
        dispatch(uploadDocumentForAsset(
            {
                files: [uploadedFiles[index]]
            }
        ))
    }

    const handleDocumentUploadAmc = (index) => {
        dispatch(uploadDocumentForAmc(
            {
                files: [uploadedFilesAmc[index]]
            }
        ))
    }

    useEffect(() => {
        if (documentsForAnAsset) {
            setDocumentListArray(documentsForAnAsset)
            // setIsLoading(false)
        }
    }, [documentsForAnAsset])

    useEffect(() => {
        if (documentsForAnAmc) {
            setDocumentListArrayAmc(documentsForAnAmc)
            // setIsLoading(false)
        }
    }, [documentsForAnAmc])


    const handleErrorFromDocument = (value) => {
        setAlertShow(true)
            setAlertType('error')
            setAlertMessage('Error')
            setAlertValue(value)
            setAlertButtons([{
                name: 'Close', action: () => {
                    setAlertShow(false)
                }
            }])
    }


    const handleFilesSelected = (files) => {
        const processedFiles = [];
        files.forEach((file) => {
            const reader = new FileReader();
            reader.onloadend = function () {
                const base64FileContent = reader.result.split(",")[1];
                const processedFile = {
                    file_content: base64FileContent,
                    object_type: "asset_document",
                    file_description: file.description,
                    file_type: file.file.type.split("/")[1],
                    file_name: file.file.name,
                    file_size: file.file.size,
                    flag: file.flag,
                    user_id: localStorage.getItem('username'),
                    asset_id: creatingNewAssetResponse || focusedAssetId,
                };
                processedFiles.push(processedFile);
                if (processedFiles.length === files.length) {
                    setUploadedFiles(processedFiles);
                }
            };
            reader.readAsDataURL(file.file);
        });
    };

    const handleFilesSelectedAmc = (files) => {
        const processedFiles = [];
        files.forEach((file) => {
            const reader = new FileReader();
            reader.onloadend = function () {
                const base64FileContent = reader.result.split(",")[1];
                const processedFile = {
                    file_content: base64FileContent,
                    object_type: "amc_document",
                    file_description: file.description,
                    file_type: file.file.type.split("/")[1],
                    file_name: file.file.name,
                    file_size: file.file.size,
                    flag: file.flag,
                    user_id: localStorage.getItem('username'),
                    // need to come back and change this
                    amc_id: selectedAmcId,
                };
                processedFiles.push(processedFile);
                if (processedFiles.length === files.length) {
                    setUploadedFilesAmc(processedFiles);
                }
            };
            reader.readAsDataURL(file.file);
        });
    };


    // ========================= document upload functions ============================



  return (
    <>
            <>
                <NavBarTop />
                <LeftNavBar />
                {
                    contractApiLoader ?
                    <Loader />
                    :
                    null
                }
                <AlertPopUp alertShow={alertShow} alertType={alertType}
                 alertMsg={alertMessage} alertValue={alertValue} alertButtons={alertButtons}/>
            </>
            {
                currentPage === 1 ? (
            <div className='meter_reports_container'>
                    <div className='d-flex justify-content-between align-items-center flex-wrap'>
                        <div className='d-flex align-items-center gap-3 mb-3'>
                            <h3 className="invoice-heading" >Assets</h3>
                        </div>
                        <div className='d-flex align-items-center gap-3 mb-3'>
                            <div className='fin_man_meter_search_bar_container'>
                                <input type='search' placeholder='Search' value={searchText} className='fin_man_meter_search_bar' 
                                onChange={handleSearchChange} 
                                />
                                <GoSearch className='fin_man_meter_search_bar_icon' />
                            </div>
                            <button className='invoice_btn'
                                onClick={() => handleAddNewAsset()} 
                                style={{ color: "white" }}>
                                    <span className='generate_invoice'><img src={"./images/icons/newContract.svg"} style={{ marginRight: "10px" }} alt="+" />
                                Add New Asset</span><span className='generate'><img src={"./images/icons/newContract.svg"} style={{ marginRight: "10px" }} alt="+" />Add</span>
                                </button>
                        </div>
                    </div>

                    <div className='table_container_commercial_head'>
                        <TableComponent
                            headers = {[{
                                name: "Asset Name",
                                type: "text",
                                key: "asset_name",
                                sort: true
                            }, {
                                name: "Asset Category",
                                type: "text",
                                key: "asset_category",
                                sort: true
                            }, {
                                name: "Asset Number",
                                type: "text",
                                key: "asset_number",
                                sort: true
                            },
                            {
                                name:"Purchase Date",
                                type:"date",
                                key:"purchase_date",
                                sort:true
                            }, 
                            {
                                name: "Asset Cost",
                                type: "number",
                                key: "asset_cost",
                                sort: true
                            },
                            {
                                name: "Location",
                                type: "text",
                                key: "space",
                                sort: true
                            },
                            {
                                name: "Created By",
                                type: "text",
                                key: "created_by",
                                sort: true
                            }, {
                                name: "Last Updated At",
                                type: "time_stamp",
                                key: "updated_at",
                                sort: true
                            }, 
                            {
                                name: "Action",
                                type: "button",
                                buttons: [{
                                    name: "View",
                                    icon: "./images/icons/ViewViewIconEye.svg",
                                    action: (data) => handleViewActionClicked(data)
                                }, 
                                {
                                    name: "Edit",
                                    icon: "./images/icons/Edit.svg",
                                    action: (data) => handleEditActionClicked(data)
                                }
                            
                            ]
                            }] }

                            data={allAssetsListTableData}
                            />
                            
                    </div>
                    <Pagination
                        handledPaginationNextClicked={handledPaginationNextClicked}
                        handledPaginationPrevClicked={handledPaginationPrevClicked}
                        isApplyClicked
                        totalRecords={totalRecords}
                        recordsPerPage={RECORDS_PER_PAGE}
                        currentPaginationPage={currentPaginationPage}
                    />
                </div>
                ) : null
            }

            {
                currentPage === 2 ? (
                    <div className="newAssetContainer">
                        <div style={{display:"flex", flexDirection:"column", margin:'10px', width:"100vw", marginBottom:"100px"}}>
                            <div>
                                {
                                    !viewClicked && !editClicked ?
                                        <h2>Add New Asset</h2>
                                        :
                                        <h2>Asset Details</h2>
                                }
                                
                            </div>
                            <div style={{display:"flex", flexDirection:"row"}}>
                                <label className='add-facility-modal-label' style={{flexWrap:"nowrap", marginRight:"10px"}}>Asset Name: </label>
                                     <spam><strong>{assetName}</strong></spam>
                            </div>
                            <div style={{marginTop:"20px"}}>
                                <Tabs
                                    activeKey={activeTab}
                                    onSelect={(k) => setActiveTab(k)}
                                    id="uncontrolled-tab-example"
                                    className="mb-0"
                                    >
                                    <Tab eventKey="1" title="Asset Details" 
                                    className="assetTabs" disabled={!viewClicked && !editClicked}>
                                        <div className="infoBody">
                                            <div>
                                                <h7 style={{ fontWeight: "bold" }}>Location of the Asset</h7>
                                                <div className="infoBodyInputs">
                                                    <div className="responsiveInputSection">
                                                        <div >
                                                            <label className='add-facility-modal-label' 
                                                            >Facility Name {<span style={{ color: "red" }}>*</span>}</label>
                                                            <select 
                                                            disabled 
                                                            value={localStorage.getItem("selected_facility_id")} 
                                                            onChange={handleFacilityNameChange} 
                                                            className='form-select responsive-add-facility-input-fields'>
                                                                {facilitiesList?.map((facility) => (
                                                                    <option key={facility.facility_id} value={facility.facility_id}>
                                                                        {facility.name}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                        <div >
                                                            <label className='add-facility-modal-label' 
                                                            >Space Name {<span style={{ color: "red" }}>*</span>}</label>
                                                            <input type='text'
                                                                    value={spaceName}
                                                            disabled={viewClicked}
                                                                    
                                                                    onChange={handleSpaceNameChange}
                                                                        className='form-control responsive-add-facility-input-fields' />
                                                            {!isSpaceNameValid &&
                                                                <span className='input-error-text'>Must be min of 3 to max of 200 characters</span>}
                                                        </div>
                                                        <div>
                                                            <label className='add-facility-modal-label'>Billing Entity {<span style={{ color: "red" }}>*</span>}</label>
                                                            <select disabled={viewClicked} className='form-select responsive-add-facility-input-fields' value={billingEntity} onChange={handleBillingEntityChange}>
                                                                <option hidden>Select</option>
                                                                {
                                                                    billingEntities?.map((entity, index) => (
                                                                        <option key={entity.id} value={entity.partner_company_id}>{toTitleCase(entity.company_name)}</option>
                                                                    ))
                                                                }

                                                            </select>
                                                        </div>
                                                        
                                                        
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                            <hr></hr>
                                            <div>
                                                <h7 style={{ fontWeight: "bold" }}>Asset Details</h7>
                                                <div className="infoBodyInputs">
                                                    <div className="responsiveInputSection" style={{justifyContent: "space-between"}}>
                                                        <div >
                                                            <label className='add-facility-modal-label' 
                                                            >Asset Category {<span style={{ color: "red" }}>*</span>}</label>
                                                            <select 
                                                            disabled={viewClicked} 
                                                            value={assetCategory} 
                                                            onChange={handleAssetCategoryChange} 
                                                            className='form-select responsive-add-facility-input-fields'>
                                                                <option value="" hidden>Select</option>
                                                                {allAssetCategoryList?.map((category, index) => (
                                                                    <option key={category.index} value={category.lov_value}> 
                                                                    {category.lov_value}
                                                                    </option>
                                                                ))}
                                                                <option 
                                                                style={{backgroundColor:"#C6C6C6", color:"black", margin:"10px"}} 
                                                                value="addAssetCategory"> <span >+ Add Category</span></option>
                                                            </select>
                                                        </div>
                                                        {
                                                            assetCategory === "addAssetCategory" ? (
                                                                <div style={{display:"flex", flexDirection:"row"}}>
                                                                <div>
                                                                    <label className='add-facility-modal-label'>Add Asset Category <span style={{ color: "red" }}>*</span></label>
                                                                    <input type='text'
                                                                    value={newAssetCategory}
                                                                    
                                                                    onChange={handleNewAssetCategoryChange}
                                                                        className='form-control responsive-add-facility-input-fields' 
                                                                        // style={{width:"0%"}}
                                                                        />
                                                                </div>
                                                                <div style={{display:"flex", alignItems:"end"}}>
                                                                        <button className='form-control' style={{height:"70%", backgroundColor:"#B2B2B2", marginLeft:"10px"}}>+</button>
                                                                </div>
                                                        </div>
                                                            ) : null
                                                        }
                                                        
                                                        <div>
                                                        
                                                        <label className='add-facility-modal-label' 
                                                            // disabled={viewClicked}
                                                            >Status {<span style={{ color: "red" }}>*</span>}</label>
                                                            <select 
                                                            disabled={viewClicked} 
                                                            value={status} 
                                                            onChange={handleStatusChange} 
                                                            className='form-select responsive-add-facility-input-fields'>
                                                                <option value="" hidden>Select</option>
                                                                {allAssetStatusList?.map((status, index) => (
                                                                    <option key={status.index} value={status.lov_value}>
                                                                        {status.lov_value}
                                                                    </option>
                                                                ))}
                                                            </select>

                                                        </div>
                                                        
                                                    </div>
                                                        
                                                    <div className="responsiveInputSection">
                                                        <div >
                                                            <label className='add-facility-modal-label'>Asset Name <span style={{ color: "red" }}>*</span></label>
                                                            <input type='text'
                                                            value={assetName}
                                                            disabled={viewClicked}
                                                            onChange={handleAssetNameChange}
                                                                className='form-control responsive-add-facility-input-fields' />
                                                        {isAssetNameValid ? null : <span className='input-error-text'>Must be min of 3 to max of 200 characters</span>}
                                                        {isAssetNameUnique ? null : <span className='input-error-text'>Asset Name already exists</span>}
                                                        </div>
                                                        <div>
                                                            <label className='add-facility-modal-label'>Asset Number<span style={{ color: "red" }}>*</span></label>
                                                            <input 
                                                            type='text' 
                                                            value={assetNumber}
                                                            disabled={viewClicked}
                                                            onChange={handleAssetNumberChange}
                                                                className='form-control responsive-add-facility-input-fields' />
                                                        {isAssetNumberValid ? null : <span className='input-error-text'>Must be min of 3 to max of 200 characters</span>}
                                                        {isAssetNumberUnique ? null : <span className='input-error-text'>Asset Number already exists</span>}
                                                        </div>
                                                        <div >
                                                            <label className='add-facility-modal-label' 
                                                            // disabled={viewClicked}
                                                            >Asset Type {<span style={{ color: "red" }}>*</span>}</label>
                                                            <select 
                                                            disabled={viewClicked}
                                                            value={assetType}
                                                            onChange={handleAssetTypeChange}
                                                            className='form-select responsive-add-facility-input-fields'>
                                                                <option value="" hidden>Select</option>
                                                                {allAssetTypeList?.map((type, index) => (
                                                                    <option key={index} value={type.lov_value}>
                                                                        {type.lov_value}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                        
                                                    </div>
                                                    <div className="responsiveInputSection">
                                        
                                                        <div >
                                                            <label className='add-facility-modal-label'>Date of Installation { <span style={{ color: "red" }}>*</span>}</label>
                                                            <input 
                                                            disabled={viewClicked} 
                                                            type='date'

                                                                min={new Date()}
                                                                value={installationDate}
                                                                onChange={handleInstallationDateChange}
                                                                onKeyDown={(e) => e.preventDefault()}
                                                                className='form-control responsive-add-facility-input-fields' />
                                                        </div>
                                                        <div>
                                                            <label className='add-facility-modal-label'>Brand<span style={{ color: "red" }}>*</span></label>
                                                            <input 
                                                            type='text' 
                                                            value={brand}
                                                            disabled={viewClicked}
                                                            onChange={handleBrandChange}
                                                                className='form-control responsive-add-facility-input-fields' />
                                                                {isBrandValid ? null : <span className='input-error-text'>Must be min of 3 to max of 200 characters</span>}
                                                        </div>
                                                        
                                                    </div>
                                                    <div className="responsiveInputSection">
                                                        <span>
                                                            Description
                                                        </span>
                                                        <textarea 
                                                        disabled={viewClicked}
                                                        className='form-control' 
                                                        required style={{ border: "0.5px solid #9D9D9C", marginLeft:"20px" }} 
                                                        value={description} 
                                                        onChange={handleDescriptionChange}
                                                         />
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </Tab>
                                    <Tab eventKey="2" title="Purchase Details" className="assetTabs" 
                                    disabled={!viewClicked && !editClicked}>
                                    <div className="infoBody">
                                            <div>
                                                {/* <h7 style={{ fontWeight: "bold" }}>Location of the Asset</h7> */}
                                                <div className="infoBodyInputs">
                                                    <div className="responsiveInputSection">
                                                        <div >
                                                            <label className='add-facility-modal-label'>Purchase Order No. <span style={{ color: "red" }}>*</span></label>
                                                            <input type='text'
                                                                value={purchaseOrderNo}
                                                                disabled={viewClicked}
                                                                onChange={handlePurchaseOrderChange}
                                                                className='form-control responsive-add-facility-input-fields' />
                                                            {!isPurchaseOrderNumberValid && <span className='input-error-text'>Must be min of 3 to max of 200 characters</span>}
                                                        </div>
                                                        <div >
                                                            <label className='add-facility-modal-label'>Date of Purchase <span style={{ color: "red" }}>*</span></label>
                                                            <input type='date'
                                                            max={installationDate}
                                                                value={purchaseDate}
                                                                disabled={viewClicked}
                                                                onChange={handlePurchaseDateChange}
                                                                className='form-control responsive-add-facility-input-fields' />
                                                        </div>
                                                        <div >
                                                            <label className='add-facility-modal-label'>Cost of asset <span style={{ color: "red" }}>*</span></label>
                                                            <input type='number'
                                                                value={assetCost}
                                                                disabled={viewClicked}
                                                                onChange={handleAssetCostChange}
                                                                onWheel={(e) => e.target.blur()}
                                                                className='form-control responsive-add-facility-input-fields' />
                                                        </div>
                                                        
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                            <hr></hr>
                                            <div>
                                                <h7 style={{ fontWeight: "bold" }}>Vendor / Supplier Information</h7>
                                                <div className="infoBodyInputs">
                                                    <div className="responsiveInputSection">
                                                        <div >
                                                            <label className='add-facility-modal-label' 
                                                            
                                                            >Select a Vendor / Supplier {<span style={{ color: "red" }}>*</span>}</label>
                                                            <select 
                                                            disabled={viewClicked} 
                                                            value={selectVendorSupplier} 
                                                            onChange={handleVendorSupplierChnge} 
                                                            className='form-select responsive-add-facility-input-fields'>
                                                                <option value="" hidden>Select</option>
                                                                {
                                                                    getAllCompaniesForDropdown?.map((company) => (
                                                                        <option key={company.company_id} value={company.company_id}>
                                                                            {company.company_name}
                                                                        </option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </div>
                                                        <div>
                                                            <Button className="form-control" disabled={selectServiceProvider} 
                                                            onClick={() => window.open('/companiesListPage', '_blank')}
                                                            style={{ backgroundColor: "#B2B2B2", height: "70%", border:"none" }}>
                                                                + Add New Vendor / Supplier
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <hr></hr>
                                            {selectVendorSupplier !== ""?(
                                                <>
                                                    <div>
                                                        <div className="infoBodyInputs">
                                                            <div className="responsiveInputSection">
                                                                <div>
                                                                    <label className='add-facility-modal-label'>Vendor Name { <span style={{ color: "red" }}>*</span>}</label>
                                                                    <input 
                                                                    disabled={true} 
                                                                    type='text'
                                                                    value={vendorName}
                                                                    className='form-control responsive-add-facility-input-fields' />
                                                                </div>
                                                                <div >
                                                                    <label className='add-facility-modal-label'>Vendor Contact Number { <span style={{ color: "red" }}>*</span>}</label>
                                                                    <input 
                                                                    disabled={true} 
                                                                    type='text' 
                                                                    value={vendorContactNumber}
                                                                    className='form-control responsive-add-facility-input-fields' />
                                                                </div>
                                                                <div >
                                                                    <label className='add-facility-modal-label'>Vendor Email {<span style={{ color: "red" }}>*</span>}</label>
                                                                    <input 
                                                                    disabled={true} 
                                                                    type='text' 
                                                                    value={vendorEmail}
                                                                    className='form-control responsive-add-facility-input-fields' />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                    <hr></hr>
                                                        <div className="responsiveInputSection">
                                                            <div style={{ display: "flex", flexDirection: "row" }}>
                                                                <div style={{ display: "flex", flexDirection: "column" }}>
                                                                    <label className='add-facility-modal-label'>GST Number </label>
                                                                    <input 
                                                                    disabled={true} 
                                                                    type='text' 
                                                                    value={gstNumberVendor} 
                                                                    className='form-control ' 
                                                                    style={{ width: "90%", border: "0.5px solid #9D9D9C" }} />
                                                                </div>
                                                        </div>
                                                            <div>
                                                                <label className='add-facility-modal-label'>CIN
                                                                    </label>
                                                                <input type='text' 
                                                                disabled={true} 
                                                                value={cinNumberVendor}  
                                                                className='form-control responsive-add-facility-input-fields' />
                                                            </div>
                                                        </div>
                                                    <div>
                                                        <div className="infoBodyInputs">
                                                            <div className="responsiveInputSection">
                                                                <div>
                                                                    <label className='add-facility-modal-label'>Company Name </label>
                                                                    <input type='text' disabled={true} 
                                                                    value={companyNameVendor}
                                                                    className='form-control responsive-add-facility-input-fields' />
                                                                </div>
                                                                <div >
                                                                    <label className='add-facility-modal-label'>PAN </label>
                                                                    <input type='text' 
                                                                    disabled={true} 
                                                                    value={panNameVendor}
                                                                    className='form-control responsive-add-facility-input-fields' />
                                                                </div>
                                                                <div>
                                                                    <label className='add-facility-modal-label'>Street Address </label>
                                                                    <input type='text' 
                                                                    disabled={true} 
                                                                    value={registeredStreetAddressVendor}
                                                                    className='form-control responsive-add-facility-input-fields' />
                                                                </div>
                                                            </div>
                                                            <div className="responsiveInputSection">

                                                                <div >
                                                                    <label className='add-facility-modal-label'>Street Address Lane 2 </label>
                                                                    <input type='text' 
                                                                    disabled={true} 
                                                                    value={registeredStreetAddressLane2Vendor}
                                                                    className='form-control responsive-add-facility-input-fields' />
                                                                </div>
                                                                <div >
                                                                    <label className='add-facility-modal-label'>City </label>
                                                                    <input type='text' disabled={true} 
                                                                    value={registeredCityVendor}
                                                                    className='form-control responsive-add-facility-input-fields' />
                                                                </div>
                                                                <div >
                                                                    <label className='add-facility-modal-label'>State  </label>
                                                                    <input type='text' disabled={true} 
                                                                    value={registeredStateProvinceVendor}
                                                                    className='form-control responsive-add-facility-input-fields' />
                                                                </div>
                                                            </div>
                                                            <div className="responsiveInputSection">

                                                                <div >
                                                                    <label className='add-facility-modal-label'>Pincode </label>
                                                                    <input type='text' disabled={true} 
                                                                    value={registeredPostalCodeVendor}
                                                                    className='form-control responsive-add-facility-input-fields' />
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr></hr>
                                                    <div>
                                                        <div style={{ display: "flex", justifyContent: "space-between", }}>
                                                            <h7 style={{ fontWeight: "bold" }}>Billing Address</h7>
                                                        </div>
                                                        <div className="infoBodyInputs">
                                                            <div className="responsiveInputSection">
                                                                <div>
                                                                    <label className='add-facility-modal-label'>Street Address </label>
                                                                    <input disabled={true} type='text' 
                                                                    value={billingStreetAddressVendor}
                                                                    className='form-control responsive-add-facility-input-fields' />
                                                                </div>
                                                                <div >
                                                                    <label className='add-facility-modal-label'>Street Address Lane 2 </label>
                                                                    <input disabled={true} type='text' 
                                                                    value={billingStreetAddressLane2Vendor}
                                                                    className='form-control responsive-add-facility-input-fields' />
                                                                </div>
                                                                <div >
                                                                    <label className='add-facility-modal-label'>City </label>
                                                                    <input disabled={true} type='text' 
                                                                    value={billingCityVendor}
                                                                    className='form-control responsive-add-facility-input-fields' />
                                                                </div>
                                                            </div>
                                                            <div className="responsiveInputSection">
                                                                <div >
                                                                    <label className='add-facility-modal-label'>State </label>
                                                                    <input disabled={true} type='text' 
                                                                    value={billingStateProvinceVendor}
                                                                    className='form-control responsive-add-facility-input-fields' />
                                                                </div>
                                                                <div >
                                                                    <label className='add-facility-modal-label'>Pincode </label>
                                                                    <input disabled={true} type='text'
                                                                    
                                                                    value={billingPostalCodeVendor}
                                                                    className='form-control responsive-add-facility-input-fields' />
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr></hr>
                                                    <div>
                                                        <h7 style={{ fontWeight: "bold" }}>Shipping Address</h7>
                                                        <div className="infoBodyInputs">
                                                            <div className="responsiveInputSection">
                                                                <div>
                                                                    <label className='add-facility-modal-label'>Street Address { <span style={{ color: "red" }}>*</span>}</label>
                                                                    <input 
                                                                    disabled={true} 
                                                                    type='text' 
                                                                    value={shippingStreetAddressVendor} 
                                                                    className='form-control responsive-add-facility-input-fields' />
                                                                </div>
                                                                <div >
                                                                    <label className='add-facility-modal-label'>Street Address Lane 2 { <span style={{ color: "red" }}>*</span>}</label>
                                                                    <input 
                                                                    disabled={true} 
                                                                    type='text' 
                                                                    value={shippingStreetAddressLane2Vendor} 
                                                                    className='form-control responsive-add-facility-input-fields' />
                                                                </div>
                                                                <div >
                                                                    <label className='add-facility-modal-label'>City {<span style={{ color: "red" }}>*</span>}</label>
                                                                    <input 
                                                                    disabled={true} 
                                                                    type='text' 
                                                                    value={shippingCityVendor} 
                                                                    className='form-control responsive-add-facility-input-fields' />
                                                                </div>
                                                            </div>
                                                            <div className="responsiveInputSection">
                                                                <div >
                                                                    <label className='add-facility-modal-label'>State { <span style={{ color: "red" }}>*</span>}</label>
                                                                    <input  type='text' 
                                                                    disabled={true} 
                                                                    value={shippingStateProvinceVendor} 
                                                                    className='form-control responsive-add-facility-input-fields' />
                                                                </div>
                                                                <div >
                                                                    <label className='add-facility-modal-label'>Pincode { <span style={{ color: "red" }}>*</span>}</label>
                                                                    <input 
                                                                    disabled={true} 
                                                                    type='text' 
                                                                    value={shippingPostalCodeVendor} 
                                                                    className='form-control responsive-add-facility-input-fields' />
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr></hr>
                                                    <div style={{ display: "flex", justifyContent: "space-between", }}>
                                                         <h7 style={{ fontWeight: "bold" }}>Contact Information</h7>
                                                    </div>

                                                    {listOfContacts && listOfContacts?.map((contact, index) => (
                                                        <div key={index} className="infoBodyInputs">
                                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: "10px" }}>
                                                                <p style={{ fontWeight: "bold" }}>Contact {index + 1}</p>
                                                            </div>
                                                            <div className="responsiveInputSection" style={{ marginTop: "0px" }}>
                                                                <div >
                                                                    <label className='add-facility-modal-label'>Contact Name { <span style={{ color: "red" }}>*</span>}</label>
                                                                    <input type='text' disabled={true} className='form-control responsive-add-facility-input-fields' value={contact.name} 
                                                                    />
                                                                </div>
                                                                <div >
                                                                    <label className='add-facility-modal-label'>Designation </label>
                                                                    <input disabled={true} type='text' className='form-control responsive-add-facility-input-fields' value={contact.designation}
                                                                         />
                                                                </div>
                                                                <div >
                                                                    <label className='add-facility-modal-label'>Phone Number</label>
                                                                    <input type='text' disabled={true} className='form-control responsive-add-facility-input-fields' value={contact.phone_number}
                                                                         />
                                                                </div>
                                                            </div>
                                                            <div className="responsiveInputSection">
                                                                
                                                                
                                                                <div >
                                                                    <label className='add-facility-modal-label'>Email <span style={{ color: "red" }}>*</span></label>
                                                                    <input type='text' disabled={true} className='form-control responsive-add-facility-input-fields'
                                                                    value={contact.email}
                                                                        />
                                                                    
                                                                </div>

                                                            </div>
                                                            
                                                        </div>
                                                    ))}
                                                
                                                    <hr></hr>
                                                </>
                                            )
                                            :
                                            null
                                            }


                                            <div>
                                                <UploadComponent
                                                    onFilesSelected={handleFilesSelected}
                                                    uploadMethod="single"
                                                    viewMode={viewClicked}
                                                    uploadedFileData={documentsListArray}
                                                    uploadActionClicked={handleDocumentUpload}
                                                    // deleteSpecificFileMain={confirmDeleteDocument}
                                                    maxNumberOfFiles={5}
                                                    handleErrorFromDocument={handleErrorFromDocument}
                                                    idType={"asset"}
                                                    acceptedFileTypes={".pdf, .jpeg, .jpg, .png"}
                                                />
                                            </div>
                                        </div>
                                    </Tab>
                                    <Tab eventKey="3" title="AMC's" className="assetTabs" 
                                    disabled={!viewClicked && !editClicked}>
                                        <div className="infoBody">
                                        {
                                                        true ? (

                                                            <div>
                                                                <div className="responsiveInputSection">
                                                                    
                                                                    <div >
                                                                        <label className='add-facility-modal-label'>Date of Purchase <span style={{ color: "red" }}>*</span></label>
                                                                        <input type='date'
                                                                        max={installationDate}
                                                                            value={purchaseDate}
                                                                            disabled={true}
                                                                            onChange={handlePurchaseDateChange}
                                                                            className='form-control responsive-add-facility-input-fields' />
                                                                    </div>
                                                                    
                                                                    
                                                                </div>
                                                                <hr></hr>
                                                        <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center", marginBottom:"10px"}}>
                                                            
                                                            
                                                            <div style={{display:"flex", alignItems:"center"}}>
                                                                <h5 style={{ fontWeight: "bold" }}>All AMC's</h5>
                                                            </div>
                                                            {/* <div style={{display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"center"}}>
                                                                    <div style={{display:"flex", flexDirection:"column", alignItems:"start", marginTop:"-10px"}}>
                                                                        <label className='add-facility-modal-label' style={{fontSize:"x-small"}}>Start Date</label>
                                                                        <input 
                                                                        
                                                                        type='date' 
                                                                         
                                                                        className='fin_man_date_range' />
                                                                    </div>
                                                                    <div style={{marginLeft:"20px", display:"flex", flexDirection:"column", alignItems:"start", marginTop:"-10px"}}>
                                                                        <label className='add-facility-modal-label' style={{fontSize:"x-small"}}>End Date</label>
                                                                        <input 
                                                                        
                                                                        type='date' 
                                                                        
                                                                        className='fin_man_date_range' />
                                                                    </div>
                                                                    <div style={{display:"flex", alignItems:"end", marginLeft:"20px"}}>
                                                                        <button className='invoice_btn'
                                                                        
                                                                        style={{ color: "white" }}>
                                                                            <span className='generate_invoice'>Apply
                                                                            </span>
                                                                        </button>
                                                                    </div>
                                                                

                                                            </div> */}
                                                            <div>
                                                                <button className='invoice_btn'
                                                                    onClick={() => setAddAmcShowModal(true)} 
                                                                    disabled={viewClicked}
                                                                    style={{ color: "white" }}>
                                                                        <span className='generate_invoice'><img src={"./images/icons/newContract.svg"} style={{ marginRight: "10px" }} alt="+" />
                                                                    Add New AMC</span><span className='generate'><img src={"./images/icons/newContract.svg"} style={{ marginRight: "10px" }} alt="+" />Add</span>
                                                                    </button>

                                                            </div>
                                                            
                                                        </div>
                                                        <Modal keyboard={false} backdrop="static" show={addAmcShowModal} centered 
                                                        // onHide={handleCloseModal} 
                                                        
                                                        size='xl'>
                                                            <Modal.Header >
                                                                <Modal.Title>AMC Details</Modal.Title>
                                                            </Modal.Header>
                                                            <Modal.Body>

                                                            <div className="infoBodyInputs" style={{maxHeight:"60vh", overflowY:"auto"}}>
                                                                            <div className="responsiveInputSection">
                                                                                <div>
                                                                                    <label className='add-facility-modal-label'>AMC Start Date { <span style={{ color: "red" }}>*</span>}</label>
                                                                                    <input 
                                                                                    min={installationDate}
                                                                                    disabled={viewAmcClicked} 
                                                                                    type='date' 
                                                                                    value={amcStartDate} 
                                                                                    onChange={handleAmcStartDateChange} 
                                                                                    className='form-control responsive-add-facility-input-fields' />
                                                                                </div>
                                                                                <div >
                                                                                    <label className='add-facility-modal-label'>AMC End Date  { <span style={{ color: "red" }}>*</span>}</label>
                                                                                    <input
                                                                                    disabled={viewAmcClicked || amcStartDate === ""} 
                                                                                    type='date' 
                                                                                    value={amcEndDate} 
                                                                                    onChange={handleAmcEndDateChange} 
                                                                                    min={amcStartDate}
                                                                                    className='form-control responsive-add-facility-input-fields' />
                                                                                </div>
                                                                                <div >
                                                                                    <label className='add-facility-modal-label'>AMC to be Renewed By {<span style={{ color: "red" }}>*</span>}</label>
                                                                                    <input 
                                                                                    min={amcStartDate}
                                                                                    disabled={viewAmcClicked}  
                                                                                    type='date' 
                                                                                    value={amcRenewedByDate}
                                                                                    onChange={handleAmcRenewedByDateChange} 
                                                                                    className='form-control responsive-add-facility-input-fields' />
                                                                                </div>
                                                                            </div>
                                                                            <div className="responsiveInputSection">
                                                                                <div >
                                                                                    <label className='add-facility-modal-label' >AMC Renewal Period {<span style={{ color: "red" }}>*</span>}</label>
                                                                                    <select 
                                                                                    disabled={viewAmcClicked}
                                                                                    onChange={handleAmcRenewalPeriodChange}
                                                                                    value={amcRenewalPeriod}
                                                                                    className='form-select responsive-add-facility-input-fields'>
                                                                                        <option value="" hidden>Select</option>
                                                                                            {
                                                                                                amcRenewalList?.map((cat, index) => (
                                                                                                    <option key={index} value={cat.lov_value}>
                                                                                                        {cat.lov_value}
                                                                                                    </option>
                                                                                                ))
                                                                                            }
                                                                                    </select>
                                                                                </div>
                                                                                <div >
                                                                                    <label className='add-facility-modal-label'>No of Services  { <span style={{ color: "red" }}>*</span>}</label>
                                                                                    <input 
                                                                                    disabled={viewAmcClicked}
                                                                                    type='number' 
                                                                                    value={amcNumberOfServices} 
                                                                                    onChange={handleAmcNumberOfServicesChange} 
                                                                                    onWheel={(e) => e.target.blur()}

                                                                                    className='form-control responsive-add-facility-input-fields' />
                                                                                </div>
                                                                                <div >
                                                                                    <label className='add-facility-modal-label'>AMC Cost {<span style={{ color: "red" }}>*</span>}</label>
                                                                                    <input 
                                                                                    disabled={viewAmcClicked}
                                                                                    type='number' 
                                                                                    value={amcCost} 
                                                                                    onChange={handleAmcCostChange} 
                                                                                    onWheel={(e) => e.target.blur()}
                                                                                    className='form-control responsive-add-facility-input-fields' />
                                                                                </div>
                                                                            </div>
                                                                            <div className="responsiveInputSection">
                                                                                <div >
                                                                                    <label className='add-facility-modal-label' >Select Category{<span style={{ color: "red" }}>*</span>}</label>
                                                                                    <select 
                                                                                    disabled={viewAmcClicked}
                                                                                    onChange={handleAmcCategoryChange}
                                                                                    value={amcCategory}
                                                                                    className='form-select responsive-add-facility-input-fields'>
                                                                                        <option value="" hidden>Select</option>
                                                                                            {
                                                                                                amcCategoryList?.map((cat, index) => (
                                                                                                    <option key={index} value={cat.lov_value}>
                                                                                                        {cat.lov_value}
                                                                                                    </option>
                                                                                                ))
                                                                                            }
                                                                                    </select>
                                                                                </div>
                                                                                
                                                                            </div>
                                                                            <hr></hr>
                                                                            <div>
                                                                                <h7 style={{ fontWeight: "bold" }}>Service Provider Information </h7>
                                                                                <div className="infoBodyInputs">
                                                                                    <div className="responsiveInputSection">
                                                                                        <div >
                                                                                            <label className='add-facility-modal-label'
                                                                                            >Select Service Provider {<span style={{ color: "red" }}>*</span>}</label>
                                                                                            <select 
                                                                                            disabled={viewAmcClicked} 
                                                                                            value={selectServiceProvider} 
                                                                                            onChange={handleServiceProviderChange} 
                                                                                            className='form-select responsive-add-facility-input-fields'>
                                                                                                <option value="">Select</option>
                                                                                                    {
                                                                                                        getAllCompaniesForDropdown?.map((company) => (
                                                                                                            <option key={company.company_id} value={company.company_id}>
                                                                                                                {company.company_name}
                                                                                                            </option>
                                                                                                        ))
                                                                                                    }
                                                                                            </select>
                                                                                        </div>
                                                                                        <div>
                                                                                            <Button className="form-control" disabled={selectServiceProvider !== ""} 
                                                                                                onClick={() => window.open('/companiesListPage', '_blank')}
                                                                                            style={{ backgroundColor: "#B2B2B2", height: "70%", border:"none" }}>
                                                                                                + Add New Service Provider
                                                                                            </Button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <hr></hr>
                                                                            {selectServiceProvider !== ""? (
                                                                                <>
                                                                                    <div className="infoBodyInputs">
                                                                                            <div className="responsiveInputSection">
                                                                                                <div>
                                                                                                    <label className='add-facility-modal-label'>Primary Contact Name { <span style={{ color: "red" }}>*</span>}</label>
                                                                                                    <input 
                                                                                                    disabled={true} 
                                                                                                    type='text' 
                                                                                                    value={primaryContactNameServiceProvider} 
                                                                                                    className='form-control responsive-add-facility-input-fields' />
                                                                                                </div>
                                                                                                <div >
                                                                                                    <label className='add-facility-modal-label'>Primary Contact Number { <span style={{ color: "red" }}>*</span>}</label>
                                                                                                    <input 
                                                                                                    disabled={true} 
                                                                                                    type='text' 
                                                                                                    value={primaryContactNumberServiceProvider} 
                                                                                                    className='form-control responsive-add-facility-input-fields' />
                                                                                                </div>
                                                                                                <div >
                                                                                                    <label className='add-facility-modal-label'>Primary Email {<span style={{ color: "red" }}>*</span>}</label>
                                                                                                    <input 
                                                                                                    disabled={true} 
                                                                                                    type='text' 
                                                                                                    value={primaryEmailServiceProvider} 
                                                                                                    className='form-control responsive-add-facility-input-fields' />
                                                                                                </div>
                                                                                            </div>
                                                                                    </div>
                                                                            
                                                                                    <hr></hr>
                                                                                    <div className="infoBodyInputs">
                                                                                        <div className="responsiveInputSection">
                                                                                            <div style={{ display: "flex", flexDirection: "row" }}>
                                                                                                <div style={{ display: "flex", flexDirection: "column" }}>
                                                                                                    <label className='add-facility-modal-label'>GST Number </label>
                                                                                                    <input 
                                                                                                    disabled={true} 
                                                                                                    type='text' 
                                                                                                    value={gstNumberServiceProvider}
                                                                                                    className='form-control ' 
                                                                                                    style={{ width: "90%", border: "0.5px solid #9D9D9C" }} />
                                                                                                </div>
                                                                                            </div>
                                                                                            <div>
                                                                                                <label className='add-facility-modal-label'>CIN
                                                                                                    </label>
                                                                                                <input type='text' 
                                                                                                disabled={true} 
                                                                                                value={cinNumberServiceProvider}  
                                                                                                className='form-control responsive-add-facility-input-fields' />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div>
                                                                                            <div className="infoBodyInputs">
                                                                                                <div className="responsiveInputSection">
                                                                                                    <div>
                                                                                                        <label className='add-facility-modal-label'>Company Name </label>
                                                                                                        <input type='text' disabled={true} 
                                                                                                        value={companyNameServiceProvider} 
                                                                                                        className='form-control responsive-add-facility-input-fields' />
                                                                                                    </div>
                                                                                                    <div >
                                                                                                        <label className='add-facility-modal-label'>PAN </label>
                                                                                                        <input type='text' 
                                                                                                        disabled={true} 
                                                                                                        value={panNameServiceProvider} 
                                                                                                        className='form-control responsive-add-facility-input-fields' />
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <label className='add-facility-modal-label'>Street Address </label>
                                                                                                        <input type='text' 
                                                                                                        disabled={true} 
                                                                                                        value={registeredStreetAddressServiceProvider}
                                                                                                        className='form-control responsive-add-facility-input-fields' />
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="responsiveInputSection">

                                                                                                    <div >
                                                                                                        <label className='add-facility-modal-label'>Street Address Lane 2 </label>
                                                                                                        <input type='text' 
                                                                                                        disabled={true} 
                                                                                                        value={registeredStreetAddressLane2ServiceProvider} 
                                                                                                        className='form-control responsive-add-facility-input-fields' />
                                                                                                    </div>
                                                                                                    <div >
                                                                                                        <label className='add-facility-modal-label'>City </label>
                                                                                                        <input type='text' disabled={true} 
                                                                                                        value={registeredCityServiceProvider} 
                                                                                                        className='form-control responsive-add-facility-input-fields' />
                                                                                                    </div>
                                                                                                    <div >
                                                                                                        <label className='add-facility-modal-label'>State  </label>
                                                                                                        <input type='text' disabled={true} 
                                                                                                        value={registeredStateProvinceServiceProvider} 
                                                                                                        className='form-control responsive-add-facility-input-fields' />
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="responsiveInputSection">

                                                                                                    <div >
                                                                                                        <label className='add-facility-modal-label'>Pincode </label>
                                                                                                        <input type='text' disabled={true} 
                                                                                                        value={registeredPostalCodeServiceProvider} 
                                                                                                        className='form-control responsive-add-facility-input-fields' />
                                                                                                    </div>

                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        </div>
                                                                                        <hr></hr>
                                                                                        <div>
                                                                                            <div style={{ display: "flex", justifyContent: "space-between", }}>
                                                                                                <h7 style={{ fontWeight: "bold" }}>Billing Address</h7>
                                                                                                
                                                                                            </div>
                                                                                            <div className="infoBodyInputs">
                                                                                                <div className="responsiveInputSection">
                                                                                                    <div>
                                                                                                        <label className='add-facility-modal-label'>Street Address </label>
                                                                                                        <input disabled={true} type='text' 
                                                                                                        value={billingStreetAddressServiceProvider} 
                                                                                                        className='form-control responsive-add-facility-input-fields' />
                                                                                                    </div>
                                                                                                    <div >
                                                                                                        <label className='add-facility-modal-label'>Street Address Lane 2 </label>
                                                                                                        <input disabled={true} type='text' 
                                                                                                        value={billingStreetAddressLane2ServiceProvider} 
                                                                                                        className='form-control responsive-add-facility-input-fields' />
                                                                                                    </div>
                                                                                                    <div >
                                                                                                        <label className='add-facility-modal-label'>City </label>
                                                                                                        <input disabled={true} type='text' 
                                                                                                        value={billingCityServiceProvider} 
                                                                                                        className='form-control responsive-add-facility-input-fields' />
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="responsiveInputSection">
                                                                                                    <div >
                                                                                                        <label className='add-facility-modal-label'>State </label>
                                                                                                        <input disabled={true} type='text' 
                                                                                                        value={billingStateProvinceServiceProvider} 
                                                                                                        className='form-control responsive-add-facility-input-fields' />
                                                                                                    </div>
                                                                                                    <div >
                                                                                                        <label className='add-facility-modal-label'>Pincode </label>
                                                                                                        <input disabled={true} type='text' 
                                                                                                        value={billingPostalCodeServiceProvider}
                                                                                                        className='form-control responsive-add-facility-input-fields' />
                                                                                                    </div>

                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <hr></hr>
                                                                                        <div>
                                                                                            <h7 style={{ fontWeight: "bold" }}>Shipping Address</h7>
                                                                                            <div className="infoBodyInputs">
                                                                                                <div className="responsiveInputSection">
                                                                                                    <div>
                                                                                                        <label className='add-facility-modal-label'>Street Address { <span style={{ color: "red" }}>*</span>}</label>
                                                                                                        <input 
                                                                                                        disabled={true} 
                                                                                                        type='text' 
                                                                                                        value={shippingStreetAddressServiceProvider} 
                                                                                                        className='form-control responsive-add-facility-input-fields' />
                                                                                                    </div>
                                                                                                    <div >
                                                                                                        <label className='add-facility-modal-label'>Street Address Lane 2 { <span style={{ color: "red" }}>*</span>}</label>
                                                                                                        <input 
                                                                                                        disabled={true} 
                                                                                                        type='text' 
                                                                                                        value={shippingStreetAddressLane2ServiceProvider} 
                                                                                                        className='form-control responsive-add-facility-input-fields' />
                                                                                                    </div>
                                                                                                    <div >
                                                                                                        <label className='add-facility-modal-label'>City {<span style={{ color: "red" }}>*</span>}</label>
                                                                                                        <input 
                                                                                                        disabled={true} 
                                                                                                        type='text' 
                                                                                                        value={shippingCityServiceProvider} 
                                                                                                        className='form-control responsive-add-facility-input-fields' />
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="responsiveInputSection">
                                                                                                    <div >
                                                                                                        <label className='add-facility-modal-label'>State { <span style={{ color: "red" }}>*</span>}</label>
                                                                                                        <input  type='text' 
                                                                                                        disabled={true}
                                                                                                        value={shippingStateProvinceServiceProvider} 
                                                                                                        className='form-control responsive-add-facility-input-fields' />
                                                                                                    </div>
                                                                                                    <div >
                                                                                                        <label className='add-facility-modal-label'>Pincode { <span style={{ color: "red" }}>*</span>}</label>
                                                                                                        <input 
                                                                                                        disabled={true} 
                                                                                                        type='text' 
                                                                                                        value={shippingPostalCodeServiceProvider} 
                                                                                                        
                                                                                                        className='form-control responsive-add-facility-input-fields' />
                                                                                                    </div>

                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <hr></hr>
                                                                                        <div style={{ display: "flex", justifyContent: "space-between", }}>
                                                                                        <h7 style={{ fontWeight: "bold" }}>Contact Information</h7>
                                                                                        </div>
                                                                                    

                                                                                    {listOfContactsServiceProvider && listOfContactsServiceProvider?.map((contact, index) => (
                                                                                        <div key={index} className="infoBodyInputs">
                                                                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: "10px" }}>
                                                                                                <p style={{ fontWeight: "bold" }}>Contact {index + 1}</p>
                                                                                            
                                                                                            </div>
                                                                                            <div className="responsiveInputSection" style={{ marginTop: "0px" }}>
                                                                                                <div >
                                                                                                    <label className='add-facility-modal-label'>Contact Name { <span style={{ color: "red" }}>*</span>}</label>
                                                                                                    <input type='text' disabled={true} className='form-control responsive-add-facility-input-fields' value={contact.name} 
                                                                                                    />
                                                                                                </div>
                                                                                                <div >
                                                                                                    <label className='add-facility-modal-label'>Designation </label>
                                                                                                    <input disabled={true} type='text' className='form-control responsive-add-facility-input-fields' value={contact.designation}
                                                                                                        />
                                                                                                </div>
                                                                                                <div >
                                                                                                    <label className='add-facility-modal-label'>Phone Number</label>
                                                                                                    <input type='text' disabled={true} className='form-control responsive-add-facility-input-fields' value={contact.phone_number}
                                                                                                        />
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="responsiveInputSection">
                                                                                                
                                                                                                
                                                                                                <div >
                                                                                                    <label className='add-facility-modal-label'>Email <span style={{ color: "red" }}>*</span></label>
                                                                                                    <input type='text' disabled={true} className='form-control responsive-add-facility-input-fields'
                                                                                                    value={contact.email}

                                                                                                        
                                                                                                        />
                                                                                                </div>
                                                                                            

                                                                                            </div>
                                                                                            
                                                                                        </div>
                                                                                    ))}
                                                                                    <hr></hr>
                                                                                    
                                                                                </>

                                                                            ) 
                                                                            :
                                                                            null
                                                                            }
                                                                            
                                                                            <div>
                                                                                {
                                                                                    editAmcClicked || viewAmcClicked ? (
                                                                                        <UploadComponent
                                                                                            onFilesSelected={handleFilesSelectedAmc}
                                                                                            uploadMethod="single"
                                                                                            viewMode={viewAmcClicked}
                                                                                            uploadedFileData={documentsListArrayAmc}
                                                                                            uploadActionClicked={handleDocumentUploadAmc}
                                                                                            // deleteSpecificFileMain={confirmDeleteDocument}
                                                                                            maxNumberOfFiles={5}
                                                                                            handleErrorFromDocument={handleErrorFromDocument}
                                                                                            idType={"Amc"}
                                                                                            acceptedFileTypes={".pdf, .jpeg, .jpg, .png"}
                                                                                        />
                                                                                    ):
                                                                                    null
                                                                                }
                                                                                
                                                                            </div>
                                                                            <hr></hr>
                                                                        
                                                                        
                                                                        </div>



                                                            </Modal.Body>
                                                            <Modal.Footer>
                                                            <>
                                                                    <Button className='btn btn-light px-5' style={{ border: "1px solid #878787", backgroundColor: "#FFFFFF" }} 
                                                                    onClick={() => {
                                                                        amcBackFunction()
                                                                    }}
                                                                    >
                                                                       {viewAmcClicked ? "Close" : "Back"}
                                                                    </Button>
                                                                    {
                                                                        !viewAmcClicked && (
                                                                        <Button className='btn btn-secondary px-5' 
                                                                            onClick={handleCreateNewAmcSubmit}
                                                                            disabled={
                                                                                !amcStartDate || !amcEndDate 
                                                                                || !amcRenewedByDate || !amcRenewalPeriod 
                                                                                || !amcNumberOfServices || !amcCost 
                                                                                || !amcCategory || !selectServiceProvider
                                                                            }
                                                                            >
                                                                                Save
                                                                            </Button>
                                                                        )
                                                                    }
                                                                    
                                                                </>
                                                            {/* {editFacilityDetails ? (
                                                                <>
                                                                    <Button className='btn btn-light px-5' style={{ border: "1px solid #878787", backgroundColor: "#FFFFFF" }} onClick={() => setShowBackCloseConfirmation(true)}>
                                                                        Back
                                                                    </Button>
                                                                    <Button className='btn btn-secondary px-5' onClick={() => handleUpdate()} disabled={modalData.name === '' || modalData.electricity_rate === '' || modalData.water_rate === '' || modalData.dg_rate === '' || modalData.dg_rate === null || modalData.address === '' || uniqueFacilityNameError || lenghtError || facilityNameError}>
                                                                        Save
                                                                    </Button>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <Button className='btn btn-light px-5' style={{ border: "1px solid #878787", backgroundColor: "#FFFFFF" }} onClick={handleCloseModal}>
                                                                        Back
                                                                    </Button>
                                                                    <Button className='btn btn-secondary px-5' style={{ border: "1px solid #878787" }} onClick={() => setEditFacilityDetails(true)}>
                                                                        Edit
                                                                    </Button>
                                                                </>
                                                            )} */}
                                                        </Modal.Footer>
                                                        </Modal>

                                                        <div className="infoBodyInputs">
                                                            <TableComponent
                                                                headers = {[{
                                                                    name: "AMC Start Date",
                                                                    type: "date",
                                                                    key: "amc_start_date",
                                                                    sort: true
                                                                }, {
                                                                    name: "AMC Service End Date",
                                                                    type: "date",
                                                                    key: "amc_end_date",
                                                                    sort: true
                                                                },
                                                                {
                                                                    name: "To be renewed by",
                                                                    type: "date",
                                                                    key: "to_be_renewed_by",
                                                                    sort: true
                                                                }
                                                                
                                                                ,{
                                                                    name: "AMC Category",
                                                                    type: "text",
                                                                    key: "amc_category",
                                                                    sort: true
                                                                }, {
                                                                    name: "Service Provider",
                                                                    type: "text",
                                                                    key: "company_details.company_name",
                                                                    sort: true
                                                                }, {
                                                                    name: "Number of Services",
                                                                    type: "number",
                                                                    key: "no_of_services",
                                                                    sort: true
                                                                }, {
                                                                    name: "AMC Cost",
                                                                    type: "text",
                                                                    key: "amc_cost",
                                                                    sort: true
                                                                },
                                                                {
                                                                    name: "Created By",
                                                                    type: "text",
                                                                    key: "created_by",
                                                                    sort: true
                                                                },
                                                                {
                                                                    name: "Created At",
                                                                    type: "time_stamp",
                                                                    key: "created_at",
                                                                    sort: true
                                                                },
                                                                {
                                                                    name: "Updated By",
                                                                    type: "text",
                                                                    key: "updated_by",
                                                                    sort: true
                                                                },
                                                                {
                                                                    name: "Updated At",
                                                                    type: "time_stamp",
                                                                    key: "updated_at",
                                                                    sort: true
                                                                },
                                                                {
                                                                    name: "Action",
                                                                    type: "button",
                                                                    buttons: [{
                                                                        name: "View",
                                                                        icon: "./images/icons/ViewViewIconEye.svg",
                                                                        action: (data) => handleViewAmcClicked(data)
                                                                    },
                                                                    {
                                                                      name: "Edit",
                                                                      icon: "./images/icons/Edit.svg",
                                                                      action: (data) => {
                                                                        if(viewClicked){
                                                                            handleViewAmcClicked(data)
                                                                        }
                                                                        else{
                                                                            handleEditAmcClicked(data)
                                                                        }
                                                                    }
                                                                  },
                                                                  
                                                                  ]
                                                                }
                                                                ] }

                                                                // maxHeightVal={"15%"}
                                                                data={allAmcForAsset?.data}
                                                                
                                                                />
                                                                <Pagination
                                                                style={{marginTop:"20px"}}
                                                                handledPaginationNextClicked={handledPaginationNextClickedAmc}
                                                                handledPaginationPrevClicked={handledPaginationPrevClickedAmc}
                                                                isApplyClicked
                                                                totalRecords={allAmcForAsset?.info?.total_no_of_records}
                                                                recordsPerPage={RECORDS_PER_PAGE}
                                                                currentPaginationPage={allAmcForAsset?.info?.page_number}
                                                            />

                                                        </div>
                                                            </div>

                                                        ) : null
                                                    }
                                                {/* <h7 style={{ fontWeight: "bold" }}>Vendor / Supplier Information</h7> */}
                                                
                                            </div>
                                    </Tab>
                                    <Tab eventKey="4" title="SOP & Forms" className="assetTabs"
                                    disabled={!viewClicked && !editClicked}>
                                    <div className="infoBody">

                                    <div>
                                                <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
                                                        <div style={{display:"flex", alignItems:"center"}}>
                                                            <h5 style={{ fontWeight: "bold" }}>Maintenance History</h5>

                                                        </div>
                                                        {/* <div style={{display:"flex", flexDirection:"row", justifyContent:"space-around"}}>
                                                                <div >
                                                                    <label className='add-facility-modal-label'>Start Date</label>
                                                                    <input 
                                                                    // disabled={viewClicked} 
                                                                    type='date' 
                                                                    // value={shippingCity} 
                                                                    // onChange={(e) => setShippingCity(e.target.value)} 
                                                                    className='form-control responsive-add-facility-input-fields' />
                                                                </div>
                                                                <div >
                                                                    <label className='add-facility-modal-label'>End Date</label>
                                                                    <input 
                                                                    // disabled={viewClicked} 
                                                                    type='date' 
                                                                    // value={shippingCity} 
                                                                    // onChange={(e) => setShippingCity(e.target.value)} 
                                                                    className='form-control responsive-add-facility-input-fields' />
                                                                </div>
                                                            

                                                        </div> */}
                                                        {/* <div style={{display:"flex", alignItems:"start"}}>
                                                            <Pagination
                                                                handledPaginationNextClicked={handledPaginationNextClicked}
                                                                handledPaginationPrevClicked={handledPaginationPrevClicked}
                                                                isApplyClicked
                                                                totalRecords={200}
                                                                recordsPerPage={10}
                                                                currentPaginationPage={currentPaginationPage}
                                                            />
                                                        </div> */}
                                                        
                                                </div>
                                                
                                                <div className="infoBodyInputs" style={{height:"60vh",overflow:"auto"}}>
                                                    <TableComponent
                                                        headers = {[{
                                                            name: "Form Type",
                                                            type: "text",
                                                            key: "form_type",
                                                            sort: true
                                                        },
                                                        {
                                                            name: "Form Name",
                                                            type: "text",
                                                            key: "form_name",
                                                            sort: true
                                                        },
                                                        
                                                        {
                                                            name: "Performed Date and Time",
                                                            type: "date",
                                                            key: "installation_date",
                                                            sort: true
                                                        }, {
                                                            name: "Performed By",
                                                            type: "text",
                                                            key: "asset_id",
                                                            sort: true
                                                        }, {
                                                            name: "Approved By",
                                                            type: "date",
                                                            key: "installation_date",
                                                            sort: true
                                                        }, {
                                                            name: "Status",
                                                            type: "text",
                                                            key: "facility_name",
                                                            sort: true
                                                        }, {
                                                            name: "Comments",
                                                            type: "text",
                                                            key: "facility_name",
                                                            sort: true
                                                        },
                                                        {
                                                            name: "Action",
                                                            type: "button",
                                                            buttons: [{
                                                                name: "View",
                                                                icon: "./images/icons/ViewViewIconEye.svg",
                                                                action: (data) => handleViewSpecificFormClicked(data)
                                                            },
                                                          
                                                          ]
                                                        }
                                                        ] }

                                                        data={MaintainanceHistoryData}
                                                        />

                                                </div>
                                                </div>
                                                
                                        {
                                            viewForm &&
                                            <ViewForm 
                                            form_items={viewForm}
                                            closeForm={handleViewFormBackClicked}
                                            />
                                        }
                                        

                                        <hr></hr>
                                        
                                            
                                                

                                           
                                        
                                            
                                    </div>
                                    </Tab>
                                </Tabs>
                            </div>
                            <div style={{marginTop:"20px",}}>

                                {
                                    activeTab === '1' && (
                                        <div style={{display:"flex", justifyContent:"end", }}>
                                            <button className="invoice_btn" style={{color:"white"}} onClick={handleBack}>Close</button>
                                            {
                                                !viewClicked ? (
                                                    <button className="invoice_btn" disabled={!spaceName || !isSpaceNameValid || !billingEntity || 
                                                        !assetCategory || !status || !assetName || !assetNumber || !assetType || !isAssetNumberValid || !isAssetNameValid
                                                        || !installationDate || !brand || !isBrandValid
                                                    } style={{marginLeft:"30px", color:"white"}} onClick={handleAssetDetailsSave}>Save and Next</button>)
                                                :
                                                (
                                                    <button className="invoice_btn"
                                                        style={{marginLeft:"30px", color:"white"}} onClick={handleNextClickedViewMode}>Next</button>
                                                )
                                            }
                                            
                                        </div>
                                    )
                                }
                                {
                                    activeTab === '2' && (
                                        <div style={{display:"flex", justifyContent:"end", }}>
                                            <button className="invoice_btn" style={{color:"white"}} onClick={handleBack}>Back</button>
                                            {
                                                !viewClicked ? (
                                                    <button className="invoice_btn"
                                                        disabled={!purchaseOrderNo || !purchaseDate || !assetCost || !isPurchaseOrderNumberValid
                                                            || !selectVendorSupplier}
                                                        style={{marginLeft:"30px", color:"white"}} onClick={handlePurchaseDetailsSave}>Save and Next</button>
                                                )
                                                :
                                                (
                                                    <button className="invoice_btn"
                                                        style={{marginLeft:"30px", color:"white"}} onClick={handleNextClickedViewMode}>Next</button>
                                                )
                                            }
                                            
                                        </div>
                                    )
                                }
                                {
                                    activeTab === '3' && (
                                        <div style={{display:"flex", justifyContent:"end", }}>
                                            <button className="invoice_btn" style={{color:"white"}} onClick={handleBack}>Back</button>
                                            {
                                                !viewClicked ? (
                                                    <button className="invoice_btn" style={{marginLeft:"30px", color:"white"}} onClick={handleAmcDetailsSave}>Save and Next</button>
                                                )
                                                :
                                                (
                                                    <button className="invoice_btn"
                                                        style={{marginLeft:"30px", color:"white"}} onClick={handleNextClickedViewMode}>Next</button>
                                                )
                                            }
                                            
                                            
                                        </div>
                                    )
                                }
                                {
                                    activeTab === '4' && (
                                        <div style={{display:"flex", justifyContent:"end", }}>
                                            <button className="invoice_btn" style={{color:"white"}} onClick={handleBack}>Back</button>
                                            {
                                                !viewClicked ? (
                                                    <button className="invoice_btn" style={{marginLeft:"30px", color:"white"}} onClick={handleCloseAssetCreationFlow}>Submit</button>
                                                )
                                                :
                                                (
                                                    <button className="invoice_btn"
                                                        style={{marginLeft:"30px", color:"white"}} onClick={handleNextClickedViewMode}>Close</button>
                                                )
                                            }
                                        </div>
                                    )
                                }
                            </div>
                            <div >
                                <spam style={{padding:"10px", color:"white"}}>extra space</spam>
                            </div>
                        </div>
                        
                    </div>
                ) : null
            }
            <Footer/>
    </>
  )
}
